import type { ReactNode, SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export function SvgCheck({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): ReactNode {
  return (
    <svg
      aria-labelledby={titleId}
      fill="currentColor"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#check_svg__a)">
        <path d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8m0 15c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7" />
        <path d="M11.15 5.29 7 9.42 4.85 7.29c-.2-.2-.52-.2-.72 0-.2.19-.2.52 0 .71l2.14 2.15.73.71.72-.71L11.87 6c.2-.19.2-.52 0-.71a.52.52 0 0 0-.73 0z" />
      </g>
      <defs>
        <clipPath id="check_svg__a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
