import type { ReactElement, SVGProps } from 'react';

export function IconChevronUpDownOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height={8}
      viewBox="0 0 8 8"
      width={8}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.493 1.999a.234.234 0 0 1-.175-.075L3.998.604l-1.323 1.32c-.1.1-.255.1-.355 0-.1-.1-.1-.255 0-.355L3.819.075c.1-.1.255-.1.355 0l1.499 1.499c.1.1.1.255 0 .355a.251.251 0 0 1-.175.075l-.005-.005Zm0 3.997a.234.234 0 0 0-.175.075l-1.32 1.32L2.68 6.07c-.1-.1-.255-.1-.355 0-.1.1-.1.255 0 .355l1.499 1.499c.1.1.255.1.355 0l1.499-1.499c.1-.1.1-.255 0-.355a.251.251 0 0 0-.175-.075h-.01Z" />
    </svg>
  );
}
