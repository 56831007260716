import type { ReactElement, SVGProps } from 'react';

export function ErrorIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path d="M15.69 7.24 8.76.31C8.55.1 8.27 0 8 0s-.55.1-.76.31L.31 7.24c-.41.42-.41 1.1 0 1.52l6.93 6.93c.21.21.49.31.76.31s.55-.1.76-.31l6.93-6.93c.41-.42.41-1.1 0-1.52ZM7.5 4.59c0-.28.22-.5.5-.5s.5.22.5.5v4.55c0 .28-.22.5-.5.5s-.5-.22-.5-.5V4.59Zm.5 7.32c-.36 0-.65-.29-.65-.65 0-.36.29-.65.65-.65.36 0 .65.29.65.65 0 .36-.29.65-.65.65Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
