import { useEffect, useState, type ReactElement } from 'react';
import { SearchField, type SearchFieldComponentProps } from '../search-field';

interface SearchFieldWithDebounceProps extends SearchFieldComponentProps {
  timeout?: number;
}
export function SearchFieldWithDebounce({
  value,
  timeout = 500,
  onChange,
  showLoading = false,
  ...props
}: SearchFieldWithDebounceProps): ReactElement {
  const [inputValue, setInputValue] = useState(props.defaultValue || value);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
      if (value !== inputValue) {
        onChange?.(inputValue || '');
      }
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this only needs to run when inputValue changes
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <SearchField
      {...props}
      onChange={val => {
        setInputValue(val);
        if (showLoading) {
          setIsLoading(true);
        }
      }}
      showLoading={isLoading}
      value={inputValue}
    />
  );
}
