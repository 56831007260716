import type { ReactElement, SVGProps } from 'react';

export function BadgeUnion(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} viewBox='0 0 32 32' width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M6.27697 13.9638c.20589-.2207.55367-.2267.76705-.0133L18.0659 24.9724c.2135.2135.2073.5616-.0137.7674-2.2313 2.078-4.8569 3.5869-7.7385 3.6116-.96401.0083-1.95502-.0655-2.88378-.2739-1.24006-.2782-2.71807-.0984-3.73593.9195l-1.34835 1.3483c-.45985.4598-1.2045.4626-1.667805.0063-.468342-.4613-.471194-1.2158-.006348-1.6806l1.320323-1.3203c1.02575-1.0258 1.19876-2.517.91713-3.7623-.21054-.9309-.28264-1.9257-.27066-2.8938.03566-2.8811 1.55689-5.4999 3.63869-7.7308Zm11.22013-3.3339-2.9788 2.9788c-.4623.4623-1.2119.4623-1.6742 0-.4624-.4623-.4624-1.2119-.0001-1.6742l2.9788-2.97879-1.8999-1.89995c-.2134-.21339-.2074-.56117.0132-.76706 2.231-2.08179 4.8498-3.60302 7.7309-3.63868.9681-.01199 1.9629.06011 2.8938.27065 1.2452.28164 2.7365.10863 3.7623-.91712L29.6434.683169c.4648-.46481 1.2193-.461945 1.6806.006386.4563.463325.4535 1.207995-.0063 1.667845l-1.3484 1.34833c-1.0178 1.01786-1.1976 2.49586-.9194 3.73593.2084.92876.2822 1.91977.2739 2.88374-.0247 2.8816-1.5336 5.5072-3.6117 7.7386-.2058.221-.5538.2271-.7674.0136l-1.932-1.932-2.975 2.9749c-.4623.4623-1.2119.4623-1.6742 0-.4623-.4623-.4623-1.2119 0-1.6742l2.975-2.975-3.8414-3.8414Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
