import type { ReactElement, SVGProps } from 'react';

export function IconCalendarOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path d="M4.747 8.62a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm4.003-.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm2.501.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm-5.753 2.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM8 11.87a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm4.002-.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
        <path
          clipRule="evenodd"
          d="M15.004 2.5h-3.002V1c0-.28-.22-.5-.5-.5s-.5.22-.5.5v1.5H4.996V1c0-.28-.22-.5-.5-.5s-.5.22-.5.5v1.5H1.005c-.55 0-1 .44-1 1v11c0 .55.45 1 1 1h13.999c.55 0 1-.45 1-1v-11c0-.56-.45-1-1-1Zm0 12h-14v-11h2.993V5c0 .27.23.5.5.5s.5-.23.5-.5V3.5h6.004V5c0 .27.23.5.5.5s.5-.23.5-.5V3.5h3.003v11Z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M.004 0h16v16h-16z" />
        </clipPath>
      </defs>
    </svg>
  );
}
