import { clsx } from 'clsx';
import React from 'react';
import { Carousel, CarouselItem } from '../carousel/index.tsx';
import { CustomPricePlan, type CustomPricePlanProps } from '../custom-price-plan/custom-price-plan.tsx';
import type { Props as PricePlanProps } from './price-plan.tsx';
import { PricePlan } from './price-plan.tsx';
import styles from './price-plans-container.strict-module.css';

export interface PricePlansContainerProps {
  pricePlans: PricePlanProps[];
  customPricePlan?: CustomPricePlanProps;
}

export function PricePlansContainer({
  pricePlans,
  customPricePlan,
}: PricePlansContainerProps): React.JSX.Element {
  return (
    <div className={styles.negativeMargin}>
      <div className={styles.mobileView}>
        <Carousel options={{ align: 'start' }} slideSize="85%" slideSpacing={0}>
          {pricePlans.map(plan => (
            <CarouselItem className={styles.pricePlanWrapper} key={plan.title}>
              <PricePlan {...plan} />
            </CarouselItem>
          ))}
          {customPricePlan ? (
            <CarouselItem className={styles.pricePlanWrapper}>
              <CustomPricePlan {...customPricePlan} />
            </CarouselItem>
          ) : null}
        </Carousel>
      </div>
      <div className={styles.desktopView}>
        <div className={styles.container}>
          {pricePlans.map((plan, index) => (
            <div
              className={clsx(styles.pricePlanWrapper, styles.column)}
              data-test-price-plan={index}
              key={plan.title}
            >
              <PricePlan {...plan} />
            </div>
          ))}
          {customPricePlan ? (
            <div className={clsx(styles.pricePlanWrapper, styles.column)} data-test-price-plan={2}>
              <CustomPricePlan {...customPricePlan} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
