import type { SVGProps, ReactElement } from 'react';

export function IconIsometricFlagEn(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.651 0 0 7.608v10.619L1.348 19v-3.605l.042.024V8.34L15 .773 13.65 0Z"
        fill="#1D1D1B"
      />
      <path
        d="m3.99 17.258 2.498-1.485v-1.81L3.99 17.258ZM10.492 13.39l2.01-1.197-2.01-.419v1.616ZM12.742 2.16l-2.25 1.25v1.737l2.25-2.987ZM1.475 15.651v1.408L3.36 14.6l-1.884 1.05ZM6.489 5.635 4.247 6.879l2.242.468V5.635ZM14.873 3.78v-.896L13.71 4.43l1.163-.65ZM1.475 9.91v1.315l1.728-.952-1.728-.362ZM14.874 9.08v-.906l-1.192.667 1.192.24Z"
        fill="#077EE0"
      />
      <path
        d="m12.513 9.492 2.36.484v-.895l-1.193-.24-1.167.65ZM11.34 10.147l-.848.472v1.155l2.01.42 2.254-1.343-3.417-.704ZM6.489 8.463V7.348L4.247 6.88 1.934 8.166l3.678.78.877-.483ZM11.397 5.72l2.313-1.29 1.163-1.545v-1.79L11.397 5.72ZM10.492 6.034l3.396-4.508-1.146.634-2.25 2.987v.887ZM1.631 18.662l-.156.094v-1.697"
        fill="#fff"
      />
      <path
        d="M3.359 14.601 1.475 17.06v1.697l.156-.094 4.05-5.354L3.358 14.6ZM6.489 13.125l-3.65 4.817 1.151-.684 2.499-3.295v-.838ZM4.429 9.598 1.475 8.97v.94l1.728.362 1.226-.675Z"
        fill="#fff"
      />
      <path d="M1.475 8.971v-.55l.46-.255" fill="#E02D2D" />
      <path
        d="m5.613 8.947-3.678-.781-.46.256v.55l2.954.626 1.184-.651ZM13.888 1.526l.986-.55v.119"
        fill="#E02D2D"
      />
      <path
        d="m10.492 6.225.906-.504 3.476-4.626V.977l-.986.549-3.396 4.508v.191ZM6.487 13.124v-.268l-.809.452-4.049 5.354 1.21-.72 3.648-4.818ZM14.873 9.976v.805l-.118.07"
        fill="#E02D2D"
      />
      <path d="m14.754 10.85.118-.069v-.805l-2.36-.484-1.175.655 3.417.704Z" fill="#E02D2D" />
      <path
        d="m13.681 8.841 1.192-.667v-.688l-2.098 1.168.906.187ZM9.817 6.037l.674-.89V3.408M7.162 13.072l-.674.89v1.811l.674-.402v-2.3ZM12.758 5.692l2.116-1.176V3.78l-1.163.65-.953 1.262ZM7.162 7.49V5.26l-.674.374v1.713l.674.143ZM9.817 11.632v2.16l.674-.403v-1.615l-.674-.142ZM3.203 10.273l-1.728.952v.765l2.705-1.51-.977-.207ZM4.286 13.39l-2.81 1.57v.691l1.883-1.05.927-1.212Z"
        fill="#fff"
      />
      <path
        d="M10.491 6.034v-.887l-.674.89v.896l.674-.9ZM4.286 13.39 3.36 14.6l2.322-1.293.906-1.2-2.3 1.281ZM6.488 13.125v.838l.674-.89v-.839l-.674.891ZM9.817 10.306v1.326l.674.143v-1.156l.847-.472-.902-.187-.62.346ZM12.513 9.492l1.168-.651-.906-.187-1.163.65.901.188ZM12.758 5.691l.953-1.26-2.314 1.289-.948 1.261 2.31-1.29ZM7.163 8.816V7.49l-.674-.142v1.114l-.876.485.956.203.594-.334ZM4.428 9.597l-1.226.676.978.207 1.217-.68-.969-.203Z"
        fill="#fff"
      />
      <path
        d="m6.569 9.15-.957-.203-1.184.65.97.204 1.17-.65ZM10.491 6.033l-.674.9v.402l.632-.354.948-1.261-.906.504v-.19ZM7.163 11.786l-.577.322-.906 1.2.809-.452v.269l.674-.891v-.448ZM10.436 9.96l.902.187 1.175-.655-.902-.187-1.175.655Z"
        fill="#fff"
      />
      <path
        d="m10.829 8.259 1.946.395 2.098-1.168v-2.97l-2.115 1.176-1.93 2.567ZM6.194 10.903 4.18 10.48l-2.705 1.51v2.97l2.81-1.57 1.91-2.487ZM7.163 8.816l-.594.334.594.126v-.46Z"
        fill="#E02D2D"
      />
      <path
        d="M9.817 7.824v1.11l1.795.37 1.163-.65-1.947-.395 1.93-2.567-2.31 1.29-.631.842ZM9.817 10.306l.62-.346-.62-.13v.476ZM7.163 11.343v-1.168l-1.765-.374-1.218.68 2.014.422-1.908 2.486 2.3-1.281.577-.765Z"
        fill="#E02D2D"
      />
      <path
        d="m6.568 9.15-1.171.651 1.765.374v-.899l-.594-.126ZM10.449 6.982l-.632.354v.488l.632-.842ZM6.585 12.108l.578-.322v-.443l-.578.765ZM10.436 9.96l1.176-.655-1.795-.37v.894l.62.13ZM7.163 5.26v2.23l1.349.28 1.306-1.733M7.163 13.072v2.299l2.655-1.579v-2.16l-1.348-.28-1.307 1.72Z"
        fill="#E02D2D"
      />
      <path
        d="m7.753 11.457-.59.777v.838l1.307-1.72 1.348.28v-1.326l-2.065 1.15ZM9.291 7.628l.527-.695V3.78L7.163 5.26v3.556l2.128-1.188Z"
        fill="#E02D2D"
      />
      <path
        d="M9.818 7.335v-.402l-.527.695.527-.293ZM7.163 12.234l.59-.777-.59.33v.447ZM9.69 8.91l.126.025V7.824L8.51 9.56l1.18-.65Z"
        fill="#E02D2D"
      />
      <path d="M8.512 9.561v-.895l.78-1.037-2.129 1.188v.46l1.349.284Z" fill="#E02D2D" />
      <path d="m7.332 10.212-.169-.037v1.168L8.512 9.56l-1.18.65Z" fill="#E02D2D" />
      <path d="M8.511 9.56v.896l-.758 1 2.065-1.15v-.477L8.51 9.561Z" fill="#E02D2D" />
      <path d="m8.512 9.56-1.349-.284v.899l.169.037 1.18-.651Z" fill="#E02D2D" />
      <path
        d="M8.51 8.666v.895l1.306-1.737v-.488l-.526.292-.78 1.038ZM8.512 9.56l-1.349 1.783v.443l.59-.33.759-1V9.56Z"
        fill="#E02D2D"
      />
      <path d="m8.51 9.561 1.306.269v-.896L9.69 8.91l-1.18.651Z" fill="#E02D2D" />
      <path
        d="M14.874.977v9.805L1.475 18.756V8.422L14.874.977ZM15 .773 1.349 8.26V19L15 10.904V.774Z"
        fill="#1D1D1B"
      />
    </svg>
  );
}
