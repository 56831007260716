import * as React from 'react';
import { TextAreaField } from './text-area-field';
import type { TextAreaFieldComponentProps } from './text-area-field';

interface BridgeProps {
  children: React.ReactNode;
}

export type TextAreaFieldCustomLabelBridgeProps = TextAreaFieldComponentProps & BridgeProps;

/*
 * This is a bridge component that makes the TextAreaField usage of label slot possible.
 *
 * For React environments, use the TextAreaField component directly.
 */
export function TextAreaFieldCustomLabelBridge({
  children,
  ...props
}: TextAreaFieldCustomLabelBridgeProps): React.JSX.Element {
  return <TextAreaField {...props} customLabel={children} />;
}
