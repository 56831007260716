import { clsx } from 'clsx';
import styles from './dots.strict-module.css';

interface DotsProps {
  count: number;
  index: number;
}

export function Dots({ count, index }: DotsProps): JSX.Element {
  return (
    <div aria-hidden="true" className={styles.container}>
      {Array.from({ length: count }).map((_, i) => (
        <div
          className={clsx(styles.dot, i === index && styles.current)}
          data-testid="dot"
          // eslint-disable-next-line react/no-array-index-key -- static array
          key={i}
        />
      ))}
    </div>
  );
}
