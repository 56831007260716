import type { SVGProps, ReactElement } from 'react';

export function IconWarningSignOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m8 2 .09.05L14.99 14v.1l-.09.05H1.1l-.09-.05V14l6.9-11.95L8 2Zm0-1c-.37 0-.74.18-.95.55L.15 13.5c-.42.73.11 1.65.95 1.65h13.8c.85 0 1.38-.92.95-1.65L8.95 1.55C8.74 1.18 8.37 1 8 1Z" />
      <path d="M8 13.16a.65.65 0 1 0 0-1.3.65.65 0 0 0 0 1.3Zm0-7.82c-.28 0-.5.22-.5.5v4.55c0 .28.22.5.5.5s.5-.22.5-.5V5.84c0-.28-.22-.5-.5-.5Z" />
    </svg>
  );
}
