import type { ReactElement, SVGProps } from 'react';

export function IconChevronBottomOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 11.49a.47.47 0 0 1-.35-.15L1.66 5.35c-.2-.19-.2-.51 0-.7.2-.19.51-.2.7 0l5.63 5.63 5.64-5.63c.2-.2.51-.2.71 0 .2.2.2.51 0 .71l-5.99 5.99c-.1.1-.23.15-.35.15v-.01Z" />
    </svg>
  );
}
