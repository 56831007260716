import type { ReactElement, SVGProps } from 'react';

export function InfoIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path
          clipRule="evenodd"
          d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Zm.5 11.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5V6.95c0-.28.22-.5.5-.5s.5.22.5.5v4.55ZM8 5.48c-.36 0-.65-.3-.65-.65 0-.35.29-.65.65-.65.36 0 .65.29.65.65 0 .36-.29.65-.65.65Z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
