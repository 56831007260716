import type { ReactElement } from 'react';
import type { ProgressBarProps } from 'react-aria-components';
import { ProgressBar as AriaProgressBar } from 'react-aria-components';
import cx from 'clsx';
import styles from './progress-bar.strict-module.css';

interface ProgressBarComponentProps extends ProgressBarProps {
  type?: 'primary' | 'secondary';
}

export function ProgressBar({
  type = 'primary',
  ...props
}: ProgressBarComponentProps): ReactElement {
  return (
    <AriaProgressBar aria-label="progress bar" data-test-progress-bar {...props}>
      {({ percentage }) => (
        <div className={styles.wrapper}>
          <div
            className={cx(styles['progress-bar'], type === 'secondary' && styles.purple)}
            data-test-progress-bar-inner
            style={{
              width: `${percentage}%`,
            }}
          />
        </div>
      )}
    </AriaProgressBar>
  );
}
