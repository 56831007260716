import type { ReactElement } from 'react';
import type { TabListProps as AriaTabListProps } from 'react-aria-components';
import { TabList as AriaTabList } from 'react-aria-components';
import type { TabProps } from '../tab/tab';
import { Tab } from '../tab/tab';
import styles from './tab-list.strict-module.css';

export interface TabListProps extends AriaTabListProps<object> {
  tabs: TabProps[];
}

export function TabList({ tabs, ...props }: TabListProps): ReactElement {
  return (
    <AriaTabList className={styles['tab-list']} {...props}>
      {tabs.map(({ id, title, icon, hasNotifications, hasDivider, count }, index) => (
        <Tab
          count={count}
          data-test-tabs-nav-item={index}
          data-test-tabs-nav-item-value={id}
          hasDivider={hasDivider}
          hasNotifications={hasNotifications}
          icon={icon}
          id={id}
          key={id}
          title={title}
        />
      ))}
    </AriaTabList>
  );
}
