import type { ReactElement } from 'react';
import { useContext } from 'react';
import { CalendarStateContext } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { Button } from '../../../button';
import styles from './clear.strict-module.css';

export function CalendarClearValue(): ReactElement {
  const state = useContext(CalendarStateContext);
  const intl = useIntl();
  const clearSelection = (): void => {
    state.setValue(null);
  };

  return (
    <Button
      className={styles.clear}
      data-test-clear-button
      onPress={clearSelection}
      slot={null}
      type="reset"
      variant="tertiary"
    >
      {intl.formatMessage({ id: 'datepicker-btn-clear' })}
    </Button>
  );
}
