import * as React from 'react';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';
import { IconChevronRightOutlined } from '@repo/design-system-kit/assets/icons';
import { IconApplePay } from '../assets/icons/icon-apple-pay.tsx';
import { IconBankTransfer } from '../assets/icons/icon-bank-transfer.tsx';
import { IconCreditCard } from '../assets/icons/icon-credit-card.tsx';
import { IconPaypal } from '../assets/icons/icon-paypal.tsx';
import type { PaymentMethods } from './payment-methods.ts';
import styles from './payment-method-selector.strict-module.css';

const PAYMENT_METHOD_ICONS: Record<PaymentMethodValues, React.ReactNode> = {
  apple_pay: <IconApplePay aria-hidden="true" />,
  bank_transfer: <IconBankTransfer aria-hidden="true" />,
  credit_card: <IconCreditCard aria-hidden="true" />,
  paypal: <IconPaypal aria-hidden="true" />,
};

export type PaymentMethodValues = PaymentMethods;

interface PaymentMethodSelectorProps
  extends Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'title' | 'type' | 'content' | 'subtitle'
  > {
  method: PaymentMethodValues;
}

/**
 * This component is based on an incomplete implementation of the Qonto Selector component.
 * Though it adheres to the Qonto Selector API, it is not a full implementation.
 * It is meant to be changed to Qonto Selector component once it's migrated to our Design System Kit.
 */
export function PaymentMethodSelector({
  className,
  disabled = false,
  onClick,
  method,
  ...props
}: PaymentMethodSelectorProps): React.ReactNode {
  const { formatMessage } = useIntl();

  const paymentMethodTitles = {
    apple_pay: formatMessage({ id: 'receivable-invoices.public-payment-link.methods.apple-pay' }),
    bank_transfer: formatMessage({
      id: 'receivable-invoices.public-payment-link.methods.bank-transfer',
    }),
    credit_card: formatMessage({
      id: 'receivable-invoices.public-payment-link.methods.credit-card',
    }),
    paypal: formatMessage({ id: 'receivable-invoices.public-payment-link.methods.paypal' }),
  };

  return (
    <button
      className={clsx(styles.selector, styles.classic, disabled && styles.disabled, className)}
      data-test-payment-method-selector={method}
      disabled={disabled}
      onClick={onClick}
      type="button"
      {...props}
    >
      <div className={clsx('mr-24', styles.icon)}>{PAYMENT_METHOD_ICONS[method]}</div>
      <div className={styles.header}>
        <span className={clsx('body-1', styles.title)}>{paymentMethodTitles[method]}</span>
      </div>
      <div className={styles['action-icon']}>
        <IconChevronRightOutlined aria-hidden="true" />
      </div>
    </button>
  );
}
