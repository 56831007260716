import type { ReactElement } from 'react';
import { isNil } from 'es-toolkit';
import { RadioGroup } from '../radio-group';
import type { RadioGroupProps } from '../radio-group';

export interface RadioGroupBooleanProps
  extends Omit<RadioGroupProps<string>, 'onChange' | 'value' | 'defaultValue'> {
  defaultValue?: boolean;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

export type BooleanString = 'true' | 'false';

function getBooleanString(value: boolean | undefined): BooleanString | undefined {
  if (isNil(value)) {
    return undefined;
  }
  return value ? 'true' : 'false';
}

/**
 * Use this RadioGroupBoolean component to render a radio group with boolean values.
 *
 * @param props - RadioGroupBooleanProps
 * @returns ReactElement
 */
export function RadioGroupBoolean({
  children,
  onChange: onChangeProps,
  ...props
}: RadioGroupBooleanProps): ReactElement {
  const mappedProps = {
    ...props,
    onChange: onChangeProps
      ? (value: string): void => {
          onChangeProps(value === 'true');
        }
      : undefined,
    value: getBooleanString(props.value),
    defaultValue: getBooleanString(props.defaultValue),
  };

  return <RadioGroup {...mappedProps}>{children}</RadioGroup>;
}
