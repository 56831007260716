import type { SVGProps, ReactElement } from 'react';

export function IconWarningSmall(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height={12}
      viewBox="0 0 12 12"
      width={12}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="m6.713 1.112 5.176 8.955a.823.823 0 0 1-.713 1.237H.824a.823.823 0 0 1-.713-1.237l5.176-8.955a.818.818 0 0 1 1.426 0ZM5.5 4.187a.5.5 0 0 1 1 0v3a.5.5 0 1 1-1 0v-3ZM6 9.76a.65.65 0 1 0 0-1.3.65.65 0 0 0 0 1.3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
