import { safeLocalStorage } from '../helper/safe-local-storage';
import { storageAvailable } from '../helper/storage-available';

/** The environment configuration object. */
export type RunTimeEnvironment = {
  /** The backend branch name. */
  backendBranchName: string;
  /** The environment name. */
  environment: string;
  /** The frontend branch name. */
  frontendBranchName: string;
  /** The frontend EKS cluster ID. */
  frontendEksClusterId: string;
  /** The monorepo suffix. */
  monorepoSuffix: string;
  /** Flag to use Qonto environment. */
  useQontoEnv: boolean;
  /** Flag to indicate if it's a staging environment. */
  isStaging: boolean;
  /** The EKS cluster ID. */
  eksClusterId: string;
  /** The backend URL mode. */
  backendUrlMode: 'production' | 'staging' | 'local';
  /** The frontend URL mode. */
  frontendUrlMode: 'production' | 'staging' | 'development';
};

export function getUrlsTable(runTimeEnvironment: RunTimeEnvironment) {
  const {
    backendBranchName,
    frontendBranchName,
    frontendEksClusterId,
    monorepoSuffix,
    frontendUrlMode,
    backendUrlMode,
    eksClusterId,
  } = runTimeEnvironment;

  const stagingBackendBranchName =
    (storageAvailable('localStorage') && safeLocalStorage.getItem('QONTO_BACKEND_BRANCH_NAME')) ||
    backendBranchName ||
    'qa';
  const stagingBackendBranchDomain = `staging${
    (storageAvailable('localStorage') && safeLocalStorage.getItem('QONTO_CLUSTER_ID')) ||
    eksClusterId
  }.qonto.co`;

  const stagingFrontendBranchDomain = `staging${frontendEksClusterId}.qonto.co`;
  const stagingFrontendBranchName = `${monorepoSuffix}${frontendBranchName}`;

  let backendUrlsTable = {
    local: {
      announcementBaseURL:
        'https://firestore.googleapis.com/v1/projects/announcement-staging/databases/(default)/documents',
      apiBaseURL: 'https://api.dev.qonto.co',
      authBaseURL: 'https://auth.dev.qonto.co',
      billerBaseURL: 'https://biller.dev.qonto.co',
      companyCreationBaseURL: 'https://company-creation-de.dev.qonto.co',
      hubBaseURL: 'https://hub.dev.qonto.co',
      notifierBaseURL: 'https://notifier-api.dev.qonto.co',
      registerBaseURL: 'https://register.dev.qonto.co',
      registerPartnersBaseURL: 'https://partner-register.dev.qonto.co',
      verificationBaseURL: 'https://verification.dev.qonto.co',
      switchingBankApiBaseURL: 'https://switching-bank-api.dev.qonto.co',
      websocketBaseURL: 'ws://websocket.dev.qonto.co',
      landingURL: 'https://qonto.com',
      cookieDomain: 'dev.qonto.co',
      thirdPartyBaseURL: `https://thirdparty.dev.qonto.co`,
    },
    staging: {
      announcementBaseURL:
        'https://firestore.googleapis.com/v1/projects/announcement-staging/databases/(default)/documents',
      apiBaseURL: `https://api-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      authBaseURL: `https://auth-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      billerBaseURL: `https://biller-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      companyCreationBaseURL: `https://company-creation-de-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      hubBaseURL: `https://hub-api-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      notifierBaseURL: `https://notifier-api-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      registerBaseURL: `https://register-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      registerPartnersBaseURL: `https://partner-register-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      switchingBankApiBaseURL: `https://switching-bank-api-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      verificationBaseURL: `https://verification-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      websocketBaseURL: `wss://websocket-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      thirdPartyBaseURL: `https://thirdparty-${stagingBackendBranchName}.${stagingBackendBranchDomain}`,
      landingURL: 'https://landing-staging.qonto.co',
      cookieDomain: 'dev.qonto.co',
    },

    production: {
      announcementBaseURL:
        'https://firestore.googleapis.com/v1/projects/announcement-dc763/databases/(default)/documents',
      apiBaseURL: 'https://api.qonto.com',
      authBaseURL: 'https://auth.qonto.com',
      billerBaseURL: 'https://biller.qonto.com',
      companyCreationBaseURL: 'https://company-creation-de.qonto.com',
      hubBaseURL: 'https://hub-api.qonto.com',
      notifierBaseURL: 'https://notifier-api.qonto.com',
      registerBaseURL: 'https://register.qonto.com',
      registerPartnersBaseURL: 'https://partner-register.qonto.com',
      verificationBaseURL: 'https://verification.qonto.com',
      switchingBankApiBaseURL: 'https://switch-api.qonto.com',
      websocketBaseURL: 'wss://websocket.qonto.com',
      landingURL: 'https://qonto.com',
      cookieDomain: 'qonto.com',
      thirdPartyBaseURL: `https://thirdparty.qonto.com`,
    },
  } as const;

  let frontendUrlsTable = {
    development: {
      companyCreationJsURL: 'https://create.dev.qonto.co',
      appJsURL: 'https://app.dev.qonto.co',
      receivableInvoicesURL: 'https://receivable-invoices-js.dev.qonto.co',
      registerJsURL: 'https://welcome.dev.qonto.co',
      registerPartnersJsURL: 'https://register-partners-js.dev.qonto.co',
      host: 'qonto.co',
      switchingBankJsURL: 'https://switch.dev.qonto.co',
      regateOauthURL: 'https://web.staging.regate.tech/qonto/oauth',
    },
    staging: {
      companyCreationJsURL: `https://company-creation-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
      appJsURL: `https://${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
      receivableInvoicesURL: `https://receivable-invoices-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
      registerJsURL: `https://register-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
      registerPartnersJsURL: `https://register-partners-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
      switchingBankJsURL: `https://switching-bank-js-${stagingFrontendBranchName}.${stagingFrontendBranchDomain}`,
      host: 'qonto.co',
      regateOauthURL: 'https://web.staging.regate.tech/qonto/oauth',
    },
    production: {
      companyCreationJsURL: 'https://create.qonto.com',
      appJsURL: 'https://app.qonto.com',
      receivableInvoicesURL: 'https://pay.qonto.com',
      registerJsURL: 'https://welcome.qonto.com',
      registerPartnersJsURL: 'https://partners.qonto.com/',
      host: 'qonto.com',
      switchingBankJsURL: `https://switch.qonto.com`,
      regateOauthURL: 'https://web.regate.io/qonto/oauth',
    },
  } as const;

  return {
    frontendUrlsTable: frontendUrlsTable[frontendUrlMode],
    backendUrlsTable: backendUrlsTable[backendUrlMode],
  };
}
