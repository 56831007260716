import type { ReactElement, SVGProps } from 'react';

export function IconChevronRightOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.5 7.998c0 .13-.05.26-.15.35l-5.99 5.99c-.2.2-.51.2-.71 0-.2-.2-.2-.51 0-.71l5.63-5.63-5.62-5.64c-.2-.19-.2-.51 0-.7.2-.19.51-.2.7 0l5.99 5.99c.1.1.15.23.15.35Z" />
    </svg>
  );
}
