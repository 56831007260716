import type { ReactElement, SVGProps } from 'react';

export function IconErrorOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path d="m8 1 .05.02 6.926 6.93v.1L8.05 14.98 8 15l-.05-.02-6.926-6.93v-.1L7.95 1.02 8 1Zm0-1c-.27 0-.55.1-.76.31L.315 7.24c-.42.42-.42 1.1 0 1.52l6.925 6.93c.21.21.48.31.76.31s.55-.1.76-.31l6.925-6.93c.42-.42.42-1.1 0-1.52L8.76.31C8.55.1 8.28 0 8 0Z" />
        <path
          clipRule="evenodd"
          d="M8.65 11.26a.648.648 0 1 0-1.3 0 .648.648 0 1 0 1.3 0Z"
          fillRule="evenodd"
        />
        <path d="M8 4.09c-.28 0-.5.22-.5.5v4.55c0 .28.22.5.5.5s.5-.22.5-.5V4.59c0-.28-.22-.5-.5-.5Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
