import type { ReactElement } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import type { LabelProps as AriaLabelProps } from 'react-aria-components';
import { Label as AriaLabel } from 'react-aria-components';
import styles from './label.strict-module.css';

interface LabelComponentProps extends AriaLabelProps {
  isOptional?: boolean;
}

export function Label({
  className,
  children,
  isOptional = false,
  ...props
}: LabelComponentProps): ReactElement {
  const intl = useIntl();
  return (
    <AriaLabel className={cx(className, 'body-1', styles.label)} {...props}>
      {children} {isOptional ? intl.formatMessage({ id: 'form-elements-optional' }) : null}
    </AriaLabel>
  );
}
