import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

export interface PolymorphicSegment {
  identify: (params: object) => void;
  track: (eventName: string, params?: object) => void;
  reset: () => void;
  group: (params: object) => void;
}

interface SegmentContextProviderProps {
  segment: PolymorphicSegment;
  children: ReactNode;
}

export const PolymorphicSegmentContext = createContext<PolymorphicSegment | null>(null);

export function PolymorphicSegmentProvider({
  children,
  segment,
}: SegmentContextProviderProps): ReactNode {
  return (
    <PolymorphicSegmentContext.Provider value={segment}>
      {children}
    </PolymorphicSegmentContext.Provider>
  );
}

export function useSegment(): PolymorphicSegment {
  const segment = useContext(PolymorphicSegmentContext);

  if (!segment) throw new Error('this hook can only be used with PolymorphicSegmentProvider');

  return segment;
}
