const ANDROID_MAP = {
  Chrome: 53,
  Opera: 40,
  Edge: 79,
  Firefox: 36,
  'Samsung browser': 6.2,
};

const IOS_MAP = {
  Safari: 14.3,
  iOS: 14.3,
};

const versionToFloat = (version: string): number =>
  Number(version.split('.').slice(0, 2).join('.'));

export function isUnsupportedAndroid(browserName?: string, browserVersion?: string): boolean {
  if (!browserVersion) {
    return true;
  }

  const browserVersionFloat = versionToFloat(browserVersion);
  const minVersion = ANDROID_MAP[browserName as keyof typeof ANDROID_MAP];

  return (minVersion ? minVersion : 0) > browserVersionFloat;
}

export function isUnsupportedIOS(
  browserName?: string,
  browserVersion?: string,
  osVersion?: string
): boolean {
  if (!osVersion || !browserVersion) {
    return true;
  }

  if (versionToFloat(osVersion) < IOS_MAP.iOS) {
    return true;
  }

  const browserVersionFloat = versionToFloat(browserVersion);
  const minVersion = IOS_MAP[browserName as keyof typeof IOS_MAP];

  return (minVersion ? minVersion : 0) > browserVersionFloat;
}
