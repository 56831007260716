import * as React from 'react';
import { clsx } from 'clsx';
import styles from './subscription-header.strict-module.css';

interface SubscriptionHeaderProps {
  title: React.ReactNode;
  center?: boolean;
}

export function SubscriptionHeader({ center = false, title }: SubscriptionHeaderProps): React.JSX.Element {
  return (
    <h1
      className={clsx(styles.title, 'title-1', center && styles.center)}
      data-test-subscription-header-title
    >
      {title}
    </h1>
  );
}
