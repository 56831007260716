import type { ReactElement, SVGProps } from 'react';

export function IconArrowBottomOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M1.98 7.98a.5.5 0 0 1 .707 0L7.5 12.793V2a.5.5 0 0 1 1 0v10.793l4.813-4.813a.5.5 0 1 1 .707.707l-5.666 5.667a.5.5 0 0 1-.708 0L1.98 8.687a.5.5 0 0 1 0-.707Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
