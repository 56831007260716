export const APPROVAL_WORKFLOW_STEP_STATES = {
  APPROVED: 'approved',
  CURRENT: 'current',
  PENDING: 'pending',
  REJECTED: 'rejected',
};

export const APPROVAL_WORKFLOW_STEP_OPERATIONS = {
  ONE_OF: 'one_of',
  ALL_OF: 'all_of',
};

export const APPROVAL_WORKFLOW_REQUEST_TYPES = {
  REIMBURSEMENT: 'reimbursement',
  SUPPLIER_INVOICE: 'supplier_invoice',
  TRANSFER: 'transfer',
};

export const APPROVAL_WORKFLOW_CONDITION_FIELDS = {
  AMOUNT: 'amount',
  SUPPLIER_ID: 'supplier_id',
  UPLOADER_ID: 'uploader_id',
};

export const APPROVAL_WORKFLOW_CONDITION_OPERATIONS = {
  EQ: 'eq',
  GTE: 'gte',
  ONE_OF: 'one_of',
};
