import type { SVGProps, ReactElement } from 'react';

export function IconIsometricFlagFr(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.966.763 15 11.38l-4.468 2.502-.033-10.62L14.966.762Z" fill="#E02D2D" />
      <path d="M10.532 13.882 9.18 13.12 9.146 2.5l1.353.762.033 10.621Z" fill="#E02D2D" />
      <path d="m10.5 3.261.032 10.622-4.683 2.619L5.821 5.88l4.678-2.62Z" fill="#fff" />
      <path d="m5.85 16.502-1.354-.763-.028-10.617 1.353.758.028 10.622Z" fill="#fff" />
      <path d="m5.82 5.88.03 10.622L1.381 19 1.353 8.383l4.468-2.502Z" fill="#077EE0" />
      <path
        d="M5.847 5.924 14.915.85l.033 10.5-9.125 5.108-4.39 2.455-.028-10.5 4.442-2.489Z"
        stroke="#1D1D1B"
      />
      <path
        d="M13.613 0 9.146 2.498 4.468 5.122 0 7.62l.034 10.617L1.382 19 1.353 8.383 5.821 5.88l4.678-2.619L14.966.763 13.613 0Z"
        fill="#1D1D1B"
      />
    </svg>
  );
}
