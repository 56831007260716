import React from 'react';
import { clsx } from 'clsx';
import styles from './text-with-highlights.strict-module.css';

interface TextWithHighlightsProps {
  children: string;
}

export function TextWithHighlights({ children }: TextWithHighlightsProps): React.ReactNode {
  const parseText = (text: string): JSX.Element[] => {
    const parts = text.split(/(?<highlight><highlight>.*?<\/highlight>)/g);
    return parts.map((part, index) => {
      const isHighlight = part.startsWith('<highlight>') && part.endsWith('</highlight>');
      return (
        // eslint-disable-next-line react/no-array-index-key -- Static, OK to use index
        <span className={clsx(isHighlight && styles.highlightedText)} key={index}>
          {part.replace(/<\/?highlight>/g, '')}
        </span>
      );
    });
  };

  return <span>{parseText(children)}</span>;
}
