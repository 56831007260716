import type { ReactElement, SVGProps } from 'react';

export function BadgeRocket(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M13.573 2.407c-.219-.22-.664-.466-1.483-.394-1.356.114-3.498 1.395-4.863 2.76l-.224.224H5.53A.75.75 0 0 0 5 5.215L3.17 7.035a.563.563 0 0 0 .396.961h1.463c0 .263.104.515.288.701L7.31 10.71a.97.97 0 0 0 .69.287v1.442c0 .502.607.752.96.396l1.821-1.828a.75.75 0 0 0 .218-.53V9.004l.23-.23c1.365-1.365 2.646-3.507 2.76-4.863.065-.812-.182-1.271-.394-1.483zm-2.585 3.596a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
          fillRule="evenodd"
        />
        <path d="M5.5 11.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
        <path
          clipRule="evenodd"
          d="M3.21 13.499a2 2 0 0 1 .543-.386l.334-.162c.272-.132.58-.175.861-.283a1.25 1.25 0 1 0-1.615-1.615c-.109.281-.151.589-.283.86l-.162.335a2 2 0 0 1-.386.544l-.356.355a.5.5 0 1 0 .708.707z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
