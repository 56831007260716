import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { Button } from '@repo/design-system-kit';
import { Identity } from '../../svgs';
import styles from './pending.strict-module.css';

export interface PendingProps {
  fourthlineRedirectUrl: string;
  onRegenerateLink: () => void;
  onBack: () => void;
}

export function Pending({
  onBack,
  fourthlineRedirectUrl,
  onRegenerateLink,
}: PendingProps): ReactNode {
  const onRetry = (): void => {
    onRegenerateLink();
    onBack();
  };

  return (
    <div className={styles.container} data-test-pending data-test-url={fourthlineRedirectUrl}>
      <figure className={cx('mb-32', styles.illustration)} data-test-pending-illustration>
        <Identity />
      </figure>
      <h1 className="title-2 mb-16" data-test-pending-title>
        <FormattedMessage id="fourthline.pending.title" />
      </h1>
      <p className="body-1 mb-32" data-test-pending-subtitle>
        <FormattedMessage id="fourthline.pending.subtitle" />
      </p>
      <Button data-test-pending-retry onPress={onRetry} stretch type="button">
        <FormattedMessage id="fourthline.pending.cta" />
      </Button>
    </div>
  );
}
