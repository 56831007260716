import type { SVGProps, ReactElement } from 'react';

export function IconClockFilledSmall(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height={12}
      viewBox="0 0 12 12"
      width={12}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12Zm.235-9.036a.5.5 0 0 0-1 0V6.37a.5.5 0 0 0 .5.5h2.844a.5.5 0 0 0 0-1H6.235V2.964Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
