import type { ReactElement, SVGProps } from 'react';

export function IconChevronLeftOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.505 8c0-.13.05-.26.15-.35l5.98-5.99c.2-.2.51-.2.71 0 .2.2.2.51 0 .71L5.715 8l5.63 5.63c.2.2.2.51 0 .71-.2.2-.51.2-.71 0l-5.98-5.99c-.1-.1-.15-.23-.15-.35Z" />
    </svg>
  );
}
