import type { ReactElement, SVGProps } from 'react';

export function BadgeEuro(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#badge-euro_svg__a)">
        <path
          clipRule="evenodd"
          d="M13.237 8.917a1.12 1.12 0 0 1 0-1.565c.218-.21.49-.32.763-.32V4.318c0-.43-.327-.771-.73-.771H2.741c-.404 0-.742.342-.742.771v2.716c.284 0 .568.11.775.32a1.12 1.12 0 0 1 0 1.564c-.207.21-.491.32-.775.32v2.526c0 .43.338.783.742.783H13.27c.404 0 .731-.353.731-.783V9.237c-.272 0-.545-.11-.763-.32M6.39 5.857a4.2 4.2 0 0 0-.338 1.08H5.42v.677h.545v.556H5.42v.666h.6c.065.436.164.807.284 1.112.141.35.327.633.534.862a2.2 2.2 0 0 0 .753.535c.305.13.633.196.993.196.436 0 .829-.098 1.167-.284.338-.196.622-.458.829-.807l-.502-.49c-.033-.034-.054-.055-.076-.055-.044-.022-.077-.022-.098-.022-.066 0-.12.033-.186.098l-.218.218a.9.9 0 0 1-.327.207 1.3 1.3 0 0 1-.546.099c-.37 0-.687-.142-.927-.415s-.404-.698-.491-1.265h1.604a.31.31 0 0 0 .229-.088c.065-.065.098-.13.098-.207v-.37H7.154v-.557H9.26a.32.32 0 0 0 .316-.305v-.372H7.231c.109-.523.283-.916.534-1.178s.557-.392.928-.392c.152 0 .261.021.36.043q.163.05.261.098c.066.044.131.088.186.131l.153.131.109.098c.032.033.065.044.12.044.065 0 .109-.022.142-.044l.098-.098.403-.458a2.4 2.4 0 0 0-.763-.71q-.459-.26-1.113-.26c-.327 0-.633.065-.927.185-.284.12-.535.294-.764.523-.218.218-.404.491-.567.818"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="badge-euro_svg__a">
          <path d="M2 2h12v12H2z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
