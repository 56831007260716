import type { ReactElement } from 'react';
import { useEffect, useState, useRef } from 'react';
import type { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';
import Lottie from 'lottie-react';

export type LottiePlayerProps = Partial<LottieComponentProps> & {
  animationData?: object;
  fetchingOptions?: RequestInit;
  onDataReady?: () => void;
  onDOMLoaded?: () => void;
  onError?: (error: LottieError) => void;
  path?: string;
  speed?: number;
};

export class LottieError extends Error {
  status: number;
  statusText: string;

  constructor(status: number, statusText: string) {
    super(statusText);

    this.name = 'LottieError';

    this.status = status;
    this.statusText = statusText;
  }
}

export function LottiePlayer({
  animationData,
  fetchingOptions,
  onDataReady,
  onDOMLoaded,
  onError,
  path,
  speed,
  ...props
}: LottiePlayerProps): ReactElement {
  const [data, setData] = useState<object | null>(null);
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    const loadAnimation = async (): Promise<void> => {
      if (animationData) {
        setData(animationData);
      } else if (path) {
        try {
          const response: Response = fetchingOptions
            ? await fetch(path, fetchingOptions)
            : await fetch(path);
          if (!response.ok) {
            throw new LottieError(response.status, response.statusText);
          } else {
            const json: object = (await response.json()) as object;
            setData(json);
          }
        } catch (error: unknown) {
          if (onError && error instanceof LottieError) {
            // We do not return the error object directly because we faced issues when doing so.
            // The error object was not being recognized as an instance of LottieError in the tests but as a string.
            // Then, we could not reach status and statusText properties in the tests.
            onError({ ...error });
          }
        }
      }
      onDataReady?.();
    };

    void loadAnimation();
  }, [animationData, fetchingOptions, path, onDataReady, onError]);

  const onDOMLoadedCallback = (): void => {
    if (lottieRef.current && speed) {
      lottieRef.current.setSpeed(speed);
    }
    onDOMLoaded?.();
  };

  return (
    <Lottie
      lottieRef={lottieRef}
      {...props}
      animationData={data}
      data-test-autoplay={props.autoplay}
      onDOMLoaded={onDOMLoadedCallback}
    />
  );
}
