export function storageAvailable(type: 'sessionStorage' | 'localStorage'): boolean {
  try {
    var storage = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch {
    return false;
  }
}
