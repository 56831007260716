import type { ReactElement } from 'react';
import { RadioButton, type RadioButtonProps } from '../radio-button';
import type { BooleanString } from '../..';

export type RadioButtonBooleanProps = Omit<RadioButtonProps<BooleanString>, 'value'> & {
  value: boolean;
};

export function RadioButtonBoolean({
  value,
  children,
  ...props
}: RadioButtonBooleanProps): ReactElement {
  return (
    <RadioButton<BooleanString> {...props} data-test-radio-boolean value={value ? 'true' : 'false'}>
      {children}
    </RadioButton>
  );
}
