import cx from 'clsx';
import type { ReactElement } from 'react';
import animationData from '../../assets/lotties/spinner.json';
import type { LottiePlayerProps } from '../lottie-player';
import { LottiePlayer } from '../lottie-player';
import styles from './spinner.strict-module.css';

interface SpinnerProps extends Omit<LottiePlayerProps, 'animationData' | 'size'> {
  color?: 'primary-a' | 'primary-b' | 'primary-b-static';
  size?: 'small' | 'medium' | 'large';
}

export function Spinner({
  color = 'primary-b',
  size = 'medium',
  className,
  ...props
}: SpinnerProps): ReactElement {
  return (
    <LottiePlayer
      animationData={animationData}
      className={cx(className, styles.spinner, styles[color], styles[size])}
      data-test-spinner=""
      {...props}
    />
  );
}
