import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { Button, Spinner } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { Steps } from '../steps';
import { QrCode } from '../../shared/qr-code';
import { BackButtonSimple } from '../../shared/back-button-simple';
import styles from './link-to-mobile.strict-module.css';

export interface LinkToMobileProps {
  isKycRequired: boolean;
  isQesRequired: boolean;
  onSendSMS: () => void;
  onRegenerateLink: () => void;
  onBack: () => void;
  fourthlineRedirectUrl: string;
  mode: string;
  isLoading?: boolean;
}

export function LinkToMobile({
  isKycRequired,
  isQesRequired,
  onRegenerateLink,
  onBack,
  onSendSMS,
  fourthlineRedirectUrl,
  mode,
  isLoading = false,
}: LinkToMobileProps): ReactNode {
  const intl = useIntl();
  const segment = useSegment();

  useEffect(() => {
    onSendSMS();
  }, [onSendSMS]);

  useEffect(() => {
    segment.track(`${mode}_fourthline_link_screen_loaded`);
  }, [mode, segment]);

  return (
    <div data-test-link-to-mobile>
      <h2 className="title-2 mb-16">
        <FormattedMessage id="fourthline.onboarding.title" />
      </h2>
      <p className="body-2 mb-32">
        <FormattedMessage id="fourthline.onboarding.subtitle" />
      </p>
      <div className={cx('mb-24', styles.insetBox)}>
        {isLoading ? (
          <div className={styles.spinnerContainer}>
            <Spinner color="primary-a" data-testid="spinner" />
          </div>
        ) : (
          <>
            <QrCode url={fourthlineRedirectUrl} />
            <div className={styles.separator}>
              <div className={styles.separationBar} />
              <span className={styles.separationText} role="separator">
                <FormattedMessage id="fourthline.onboarding.separator" />
              </span>
              <div className={styles.separationBar} />
            </div>
            <Button data-test-send-sms-button onPress={onSendSMS} type="button" variant="secondary">
              <FormattedMessage id="fourthline.onboarding.send-link" />
            </Button>
          </>
        )}
      </div>
      {isKycRequired && isQesRequired ? (
        <Steps
          className="mb-24"
          data-test-kyc-and-qes-guidance
          steps={[
            intl.formatMessage({
              id: 'fourthline.onboarding.steps.instruction-id',
            }),
            intl.formatMessage({
              id: 'fourthline.onboarding.steps.instruction-sign',
            }),
          ]}
        />
      ) : null}
      {isKycRequired && !isQesRequired ? (
        <p className="body-2 mb-24" data-test-kyc-guidance>
          <FormattedMessage id="fourthline.onboarding.kyc.guidance" />
        </p>
      ) : null}
      {isQesRequired && !isKycRequired ? (
        <p className="body-2 mb-24" data-test-qes-guidance>
          <FormattedMessage id="fourthline.onboarding.qes.guidance" />
        </p>
      ) : null}
      <div className="mb-32">
        <span className="caption">
          <FormattedMessage id="fourthline.onboarding.not-working" />
        </span>
        <button
          className={cx('caption-link', styles.regenerateLink)}
          data-test-regenerate-link
          onClick={onRegenerateLink}
          type="button"
        >
          <FormattedMessage id="fourthline.onboarding.generate-new-link" />
        </button>
      </div>
      <div className={styles.backButtonContainer}>
        <BackButtonSimple data-test-go-back onPress={onBack} />
      </div>
    </div>
  );
}
