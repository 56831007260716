import { useState, type ReactNode } from 'react';
import { clsx } from 'clsx';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import styles from './empty-states-inform.strict-module.css';

export interface EmptyStatesInformProps {
  title: string;
  subtitle: ReactNode;
  ctaText?: string;
  onCtaClick?: () => void;
  illuSrc?: string;
  lottieSrc?: string;
}

export function EmptyStatesInform({
  subtitle,
  title,
  onCtaClick,
  ctaText,
  illuSrc,
  lottieSrc,
  ...props
}: EmptyStatesInformProps): ReactNode {
  const [animationReady, setAnimationReady] = useState(false);

  return (
    <div className={styles.wrapper} {...props}>
      <div className={styles.content}>
        <div className={styles.image}>
          {illuSrc ? (
            <img alt="" data-test-empty-state-inform-image role="none" src={illuSrc} />
          ) : null}
          {!illuSrc && lottieSrc ? (
            <LottiePlayer
              className={clsx(styles.animation, !animationReady && 'hidden')}
              data-test-empty-state-inform-animation={lottieSrc}
              onDataReady={() => {
                setAnimationReady(true);
              }}
              path={lottieSrc}
            />
          ) : null}
        </div>

        <h1 className={clsx(styles.title, 'mb-8', 'title-2')} data-test-empty-state-inform-title>
          {title}
        </h1>
        <p className={clsx(styles.subtitle, 'body-2')} data-test-empty-state-inform-subtitle>
          {subtitle}
        </p>
        {ctaText ? (
          <div className={styles.buttons}>
            <Button data-test-empty-state-inform-button onPress={onCtaClick} variant="tertiary">
              {ctaText}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
