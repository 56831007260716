import {
  Button,
  IconCheckmarkRoundedFilledSmall,
  type BaseButtonProps,
} from '@repo/design-system-kit';
import React from 'react';
import { type ButtonProps as AriaButtonProps } from 'react-aria-components';
import type { CustomPricePlanProps } from '../custom-price-plan/custom-price-plan.tsx';
import type { AnnualProps, MonthlyProps } from './price-plan.tsx';
import { PricePlansContainer } from './price-plans-container.tsx';
import styles from './price-plans-container.bridge.strict-module.css';

interface MainButtonBridge {
  text: string;
  buttonProps: BaseButtonProps & AriaButtonProps;
}

type MonthlyPropsBridge = Omit<MonthlyProps, 'descriptionCta' | 'mainButton'> & {
  descriptionCta: {
    text: string;
    onPress: () => void;
  };
  mainButton: MainButtonBridge;
};

type AnnualPropsBridge = Omit<AnnualProps, 'mainButton'> & {
  mainButton: MainButtonBridge;
};

type PricePlansBridgeProps = (MonthlyPropsBridge | AnnualPropsBridge)[];

type CustomPricePlanBridgeProps = Omit<CustomPricePlanProps, 'mainButton'> & {
  mainButton: ({ type: 'button' } & MainButtonBridge) | { type: 'success'; text: string };
};

export interface PricePlansContainerProps {
  pricePlans: PricePlansBridgeProps;
  customPricePlan?: CustomPricePlanBridgeProps;
}

export function PricePlansContainerBridge({
  pricePlans,
  customPricePlan,
}: PricePlansContainerProps): React.JSX.Element {
  const mappedPricePlans = pricePlans.map(plan => {
    const mainButton = (
      <Button stretch {...plan.mainButton.buttonProps} data-test-choose-plan-button>
        {plan.mainButton.text}
      </Button>
    );

    if (plan.recurrence === 'annual') {
      return { ...plan, mainButton };
    }

    return {
      ...plan,
      descriptionCta: (
        <Button data-test-description-cta onPress={plan.descriptionCta.onPress} variant="tertiary">
          {plan.descriptionCta.text}
        </Button>
      ),
      mainButton,
    };
  });

  if (customPricePlan) {
    return (
      <PricePlansContainer
        customPricePlan={{
          ...customPricePlan,
          mainButton:
            customPricePlan.mainButton.type === 'button' ? (
              <Button stretch variant="secondary" {...customPricePlan.mainButton.buttonProps}>
                {customPricePlan.mainButton.text}
              </Button>
            ) : (
              // customPricePlan.mainButton.type === 'success'
              <div className={styles.success}>
                <IconCheckmarkRoundedFilledSmall className={styles.successIcon} />
                <span className="body-1">{customPricePlan.mainButton.text}</span>
              </div>
            ),
        }}
        pricePlans={mappedPricePlans}
      />
    );
  }

  return <PricePlansContainer pricePlans={mappedPricePlans} />;
}
