import type { ReactElement } from 'react';
import type { SearchFieldProps } from 'react-aria-components';
import { SearchField as AriaSearchField } from 'react-aria-components';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Input } from '../form-elements';
import { Button } from '../button';
import { Spinner } from '../spinner';
import { CloseIcon } from './icon/close-icon';
import { SearchIcon } from './icon/search-icon';
import styles from './search-field.strict-module.css';

export interface SearchFieldComponentProps extends SearchFieldProps {
  placeholder: string;
  compact?: boolean;
  showLoading?: boolean;
}

export function SearchField({
  placeholder,
  className,
  compact,
  showLoading,
  ...props
}: SearchFieldComponentProps): ReactElement {
  const intl = useIntl();

  return (
    <AriaSearchField
      aria-label={placeholder}
      className={cx(className, styles.container)}
      {...props}
    >
      <SearchIcon aria-hidden className={styles.icon} />
      <Input
        /* eslint-disable-next-line jsx-a11y/no-autofocus -- The search input should focus on render */
        autoFocus={props.autoFocus}
        className={cx(styles.input, compact && styles.compact)}
        data-test-search-input
        placeholder={placeholder}
      />
      <Button
        aria-label={intl.formatMessage({ id: 'a11y-buttons-clear-search-aria-label' })}
        className={styles.close}
        data-test-search-input-delete
        iconOnly
        size="small"
        variant="tertiary"
      >
        <CloseIcon aria-hidden />
      </Button>
      {showLoading ? <Spinner className={styles.spinner} color="primary-a" /> : null}
    </AriaSearchField>
  );
}
