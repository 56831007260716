import type { ReactElement, SVGProps } from "react";

export function IconNavTasks(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        height={13}
        id="a"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
        width={16}
        x="0"
        y="2"
      >
        <path d="M0 2h16v12H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          clipRule="evenodd"
          d="M14.71 3.19c-.22-.57-.78-.94-1.39-.94H2.68c-.62 0-1.17.37-1.4.94L0 6.39v6.36c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V6.41l-1.29-3.22Zm.29 9.56H1v-5h4.18c.88 2.59 4.75 2.59 5.64 0H15v5Zm-4.87-6-.14.14c-.23 2.4-3.75 2.4-3.98 0l-.15-.14H.94l1.27-3.19c.08-.19.26-.31.47-.31h10.64c.21 0 .39.12.47.31l1.27 3.19h-4.93Z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
