import type { ReactElement, SVGProps } from 'react';

export function IconUserOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#userOutlined)">
        <path d="M13.865 10.33a9.38 9.38 0 0 0-4.39-1.91c-.98-.15-1.98-.15-2.96 0-1.56.24-3.07.88-4.39 1.91-.57.45-.9 1.14-.9 1.87v2.72c0 .26.21.48.48.48h12.59c.26 0 .48-.22.48-.48V12.2c0-.73-.33-1.42-.91-1.87Zm-.09 4.07H2.225v-2.2c0-.42.19-.82.52-1.08a8.536 8.536 0 0 1 5.23-1.82h.04a8.536 8.536 0 0 1 5.23 1.82c.34.26.53.66.53 1.08v2.2Z" />
        <path
          clipRule="evenodd"
          d="M4.655 3.95a3.35 3.35 0 1 0 6.7 0 3.35 3.35 0 0 0-6.7 0Zm1 0c0 1.3 1.05 2.35 2.35 2.35s2.35-1.05 2.35-2.35-1.05-2.35-2.35-2.35-2.35 1.05-2.35 2.35Z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="userOutlined">
          <path d="M16 0H0v16h16z" />
        </clipPath>
      </defs>
    </svg>
  );
}
