import * as React from 'react';
import { clsx } from 'clsx';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import successLottie from '../../assets/lotties/success.json';
import styles from './subscription-success.strict-module.css';

interface SubscriptionSuccessProps {
  title: string;
  subtitle: string;
  ctaText: string;
  onClick: () => void;
  lottie?: object;
  stretch?: boolean;
  asset?: React.ReactNode;
}

export function SubscriptionSuccess({
  title,
  subtitle,
  ctaText,
  onClick,
  lottie = successLottie,
  asset,
  stretch = false,
}: SubscriptionSuccessProps): React.JSX.Element {
  return (
    <div className={styles.container} data-test-subscription-success>
      {asset || (
        <LottiePlayer
          animationData={lottie}
          className={styles.lottie}
          data-test-subscription-success-lottie
          loop={false}
        />
      )}

      <h1 className={clsx(styles.title, 'title-1')} data-test-subscription-success-title>
        {title}
      </h1>
      <p className={clsx(styles.subtitle, 'body-1')} data-test-subscription-success-subtitle>
        {subtitle}
      </p>
      <Button data-test-subscription-success-button onPress={onClick} stretch={stretch}>
        {ctaText}
      </Button>
    </div>
  );
}
