import type { HeadingProps, Key, DateValue } from 'react-aria-components';
import {
  Select,
  SelectValue,
  Popover,
  ListBox,
  ListBoxItem,
  useContextProps,
  HeadingContext,
  CalendarStateContext,
} from 'react-aria-components';
import type { ReactElement } from 'react';
import { useContext } from 'react';
import { useDateFormatter } from '@react-aria/i18n';
import type { CalendarDate } from '@internationalized/date';
import { getLocalTimeZone, today } from '@internationalized/date';
import cx from 'clsx';
import { Button } from '../../../button';
import { IconArrowBottom } from './icon/icon-arrow-bottom';
import styles from './heading.strict-module.css';

interface YearItem {
  value: DateValue;
  formatted: string;
}

export function CalendarHeadingSelect(
  headingProps: HeadingProps,
  headingRef: React.ForwardedRef<HTMLHeadingElement>
): ReactElement {
  const state = useContext(CalendarStateContext);

  const [props, ref] = useContextProps(headingProps, headingRef, HeadingContext);

  const years = [] as YearItem[];
  const formatter = useDateFormatter({
    year: 'numeric',
    timeZone: state.timeZone,
  });

  const baseDate = today(getLocalTimeZone());
  for (let i = -10; i <= 10; i++) {
    const date = baseDate.add({ years: i });
    years.push({
      value: date,
      formatted: formatter.format(date.toDate(state.timeZone)),
    });
  }

  const handleSelectionChange = (key: Key): void => {
    const index = Number(key);
    const newFocusedDate = years[index]?.value.set({ day: 1 });
    state.setFocusedDate(newFocusedDate as CalendarDate);
  };

  return (
    <Select aria-label="select" onSelectionChange={handleSelectionChange}>
      <Button className={styles.button} variant="tertiary">
        <div className={styles['button-children']}>
          <SelectValue className="body-1" {...props} ref={ref} />
          <IconArrowBottom aria-hidden="true" />
        </div>
      </Button>
      <Popover className={styles.popover} maxHeight={180}>
        <ListBox className={styles.list}>
          {years.map((year, i) => (
            <ListBoxItem
              className={cx('body-1', 'overlay', styles.item)}
              id={i}
              key={year.formatted}
            >
              {year.formatted}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
}
