import type { ReactNode } from 'react';
import cx from 'clsx';
import styles from './timeline.strict-module.css';
import type { TimelineItemProps } from './internal/timeline-item';
import { TimelineItem } from './internal/timeline-item';

interface TimelineProps {
  list: TimelineItemProps[];
  showLastLine?: boolean;
  className?: string;
}

export function Timeline({
  list,
  className,
  showLastLine = false,
  ...rest
}: TimelineProps): ReactNode {
  return (
    <ol
      className={cx(styles.timeline, className)}
      data-test-show-last-line={showLastLine}
      {...rest}
    >
      {list.map((item, index) => (
        <TimelineItem
          data-testid={`timeline-item-${String(index)}`}
          hideLine={index === list.length - 1 && !showLastLine}
          id={item.id}
          key={item.id}
          lineVariant={item.lineVariant}
          slots={item.slots}
        />
      ))}
    </ol>
  );
}
