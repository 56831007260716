import { clsx } from 'clsx';
import sharedStyles from './shared-groups.strict-module.css';
import styles from './addons-group.strict-module.css';

export interface Addon {
  description: string;
  iconLightPath: string;
  iconDarkPath: string;
  isAvailable?: boolean;
}

export interface AddonsGroupProps {
  title: string;
  addons: Addon[];
}

export function AddonsGroup({ title, addons }: AddonsGroupProps): React.ReactNode {
  return (
    <div data-test-addons-group>
      <h3 className={clsx('title-4', 'mb-8', sharedStyles.title)} data-test-title>
        {title}
      </h3>
      <ul className={sharedStyles.list}>
        {addons.map(({ description, iconLightPath, iconDarkPath, isAvailable = true }, index) => (
          <li
            className={clsx(
              sharedStyles['list-item'],
              styles['list-item'],
              !isAvailable && styles['not-available'],
              'mb-8'
            )}
            data-test-addon-description={index}
            key={description}
          >
            <div
              aria-hidden="true"
              className={clsx('mr-16', sharedStyles.image, styles.image)}
              style={
                {
                  '--icon-light-path': `url(${iconLightPath})`,
                  '--icon-dark-path': `url(${iconDarkPath})`,
                } as React.CSSProperties
              }
            />
            <span>{description}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
