import cx from 'clsx';
import type { ReactElement } from 'react';
import { useReducer } from 'react';
import type { TextFieldProps as AriaTextFieldProps, ValidationResult } from 'react-aria-components';
import { TextField as AriaTextField } from 'react-aria-components';
import { Description, Error, Input, Label } from '../form-elements';
import { EyeIcon } from './icons/eye';
import { EyeHiddenIcon } from './icons/eye-hidden';
import styles from './text-field.strict-module.css';

interface TextFieldComponentProps extends AriaTextFieldProps {
  label: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder: string;
  multiple?: boolean;
  isOptional?: boolean;
  isOptionalText?: string;
}

export function TextField({
  label,
  description,
  errorMessage,
  placeholder,
  className,
  type,
  multiple,
  isOptional,
  isOptionalText,
  ...props
}: TextFieldComponentProps): ReactElement {
  const [isPasswordVisible, togglePasswordVisibility] = useReducer(state => !state, false);

  const isInvalid = Boolean(errorMessage);

  return (
    <AriaTextField
      className={cx(className, styles.container)}
      isInvalid={isInvalid}
      type={!isPasswordVisible ? type : 'text'}
      {...props}
    >
      {Boolean(label) && (
        <Label>
          {label} {Boolean(isOptional) && isOptionalText}
        </Label>
      )}
      <span className={styles['input-container']}>
        <Input multiple={multiple} placeholder={placeholder} />

        {type === 'password' && (
          <button
            aria-label="Toggle password visibility"
            className={styles.icon}
            onClick={togglePasswordVisibility}
            type="button"
          >
            {isPasswordVisible ? (
              <EyeHiddenIcon aria-label="Password visible" />
            ) : (
              <EyeIcon aria-label="Password hidden" />
            )}
          </button>
        )}
      </span>
      <Error>{errorMessage}</Error>
      {Boolean(description) && <Description>{description}</Description>}
    </AriaTextField>
  );
}
