import type { ReactElement } from 'react';
import type { TextFieldProps as AriaTextFieldProps, ValidationResult } from 'react-aria-components';
import { TextField as AriaTextField } from 'react-aria-components';
import cx from 'clsx';
import { Description, Error, Label, TextArea } from '../form-elements';
import styles from './text-area-field.strict-module.css';

export interface TextAreaFieldComponentProps extends AriaTextFieldProps {
  label?: string;
  customLabel?: React.ReactNode;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder: string;
  isResizable?: boolean;
  isExpandable?: boolean;
  isOptional?: boolean;
}

export function TextAreaField({
  label,
  customLabel,
  description,
  errorMessage,
  placeholder,
  isResizable = false,
  isExpandable,
  isOptional = false,
  className,
  ...props
}: TextAreaFieldComponentProps): ReactElement {
  return (
    <AriaTextField className={cx(className, styles.container)} {...props}>
      {Boolean(label) && <Label isOptional={isOptional}>{label}</Label>}
      {Boolean(customLabel) && <div className="body-1">{customLabel}</div>}
      <TextArea
        // eslint-disable-next-line jsx-a11y/no-autofocus -- autofocus can be expected in specs
        autoFocus={props.autoFocus}
        className={styles.textarea}
        isExpandable={isExpandable}
        isResizable={isResizable}
        placeholder={placeholder}
      />
      <Error>{errorMessage}</Error>
      {Boolean(description) && <Description>{description}</Description>}
    </AriaTextField>
  );
}
