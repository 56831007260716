import type { ReactElement } from 'react';
import cx from 'clsx';
import type { TextProps } from 'react-aria-components';
import { Text } from 'react-aria-components';
import styles from './description.strict-module.css';

export function Description({ className, ...props }: TextProps): ReactElement {
  return (
    <Text className={cx(className, 'caption', styles.description)} slot="description" {...props} />
  );
}
