import type { ReactElement, SVGProps } from 'react';

export function BadgeLock(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg height="16" viewBox="0 0 12 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.74105 7.05798V5.38882C2.74105 3.51736 4.2488 2 6.10851 2C7.96821 2 9.47604 3.5174 9.47604 5.38882L9.47613 7.05798H10.318C10.6447 7.05798 10.9086 7.32355 10.9086 7.65229V13.4057C10.9086 13.7344 10.6447 14 10.318 14H1.89917C1.57251 14 1.30859 13.7344 1.30859 13.4057V7.65229C1.30859 7.32357 1.57249 7.05798 1.89917 7.05798H2.74105ZM7.79234 7.05798V5.38882C7.79234 4.45317 7.03841 3.69443 6.10855 3.69443C5.17877 3.69443 4.4248 4.45308 4.4248 5.38882V7.05798H7.79234ZM6.10938 11.5215C6.66166 11.5215 7.10938 11.0738 7.10938 10.5215C7.10938 9.9692 6.66166 9.52148 6.10938 9.52148C5.55709 9.52148 5.10938 9.9692 5.10938 10.5215C5.10938 11.0738 5.55709 11.5215 6.10938 11.5215Z"
        fill="currentColor"
      />
    </svg>
  );
}
