import type { ReactElement, SVGProps } from 'react';

export function IconBankTransfer(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={40} width={40} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect fill="#F9F9F7" height={40} rx={4} width={40} />
      <g clipPath="url(#bankTransfer)" fill="#1D1D1B">
        <path d="M20.167 18.84H14.5a.5.5 0 1 1 0-1h5.667a.5.5 0 0 1 0 1Zm-.375 3.515.446-1.04.061.212a.997.997 0 0 0 1.626.466l.037.05c.323.442.93.563 1.399.279l.236-.143a.227.227 0 0 1 .212-.013l.174.08c.159.072.332.11.507.11h.843a.5.5 0 1 0 0-1h-.843a.228.228 0 0 1-.094-.021l-.173-.08a1.227 1.227 0 0 0-1.144.069l-.236.143a.056.056 0 0 1-.075-.015l-.04-.054a.996.996 0 0 0-1.469-.151l-.258-.904c-.192-.672-1.121-.73-1.396-.088l-.732 1.706a.5.5 0 0 0 .92.394Z" />
        <path
          clipRule="evenodd"
          d="M27 24.825a1 1 0 0 0 1-1v-7.65a1 1 0 0 0-1-1H13a1 1 0 0 0-1 1v7.65a1 1 0 0 0 1 1h14Zm-14-1h14v-7.65H13v7.65Z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="bankTransfer">
          <path d="M12 28V12h16v16z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
