import cx from 'clsx';
import type { ReactElement, HTMLProps } from 'react';
import styles from './highlight.strict-module.css';

export interface HighlightProps extends HTMLProps<HTMLSpanElement> {
  text: string;
  className?: string;
  level?: 'primary' | 'secondary';
}

export function BadgeHighlight({
  text,
  className,
  level = 'primary',
  ...props
}: HighlightProps): ReactElement {
  return (
    <span
      className={cx(styles.highlight, 'caption', className)}
      data-test-badge-highlight=""
      data-test-level={level}
      {...props}
    >
      {text}
    </span>
  );
}
