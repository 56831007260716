import type { SVGProps, ReactElement } from 'react';

export function EyeHiddenIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.8909 7.22512L9.99062 8.12512C9.9306 9.13512 9.13035 9.93512 8.12004 9.99512L7.22976 10.8951C7.46983 10.9651 7.72992 11.0051 8 11.0051C9.66052 11.0051 11.0009 9.66512 11.0009 8.00512C11.0009 7.73512 10.9609 7.47512 10.8909 7.22512ZM8 5.00512C6.33948 5.00512 4.99906 6.34512 4.99906 8.00512C4.99906 8.27512 5.03907 8.53512 5.1091 8.77512L6.00938 7.87512C6.0694 6.87512 6.86965 6.07512 7.86996 6.01512L8.77024 5.10512C8.53017 5.04512 8.27008 5.00512 8 5.00512Z" />
      <path
        clipRule="evenodd"
        d="M0.0375117 7.75512C-0.0125039 7.91512 -0.0125039 8.09512 0.0375117 8.25512C0.497656 9.67512 1.23789 10.8351 2.15817 11.7351L2.85839 11.0251C2.05814 10.2451 1.40794 9.23512 1.00781 8.00512C2.42826 3.65512 6.90966 2.10512 10.5008 3.37512L11.281 2.59512C7.10972 0.865117 1.68803 2.58512 0.0375117 7.75512ZM15.9625 7.75512C15.5023 6.33512 14.7621 5.17512 13.8418 4.27512L13.1316 4.97512C13.9319 5.75512 14.5821 6.76512 14.9922 8.00512C13.5617 12.3551 9.08034 13.9051 5.48921 12.6351L4.71897 13.4151C8.89028 15.1451 14.312 13.4251 15.9625 8.25512C16.0125 8.09512 16.0125 7.91512 15.9625 7.75512Z"
        fillRule="evenodd"
      />
      <path d="M14.8421 1.85512L13.0816 3.62512L12.3614 4.33512L10.4408 6.26512L9.72054 6.98512L6.98968 9.72512L4.50891 12.2051L3.76868 12.9551L1.86808 14.8551C1.76805 14.9551 1.63801 15.0051 1.51797 15.0051C1.38793 15.0051 1.25789 14.9551 1.15786 14.8551C0.957799 14.6651 0.957799 14.3451 1.15786 14.1451L2.91841 12.3851L3.62863 11.6651L6.27946 9.01512L9.01031 6.27512L9.73054 5.55512L11.4811 3.79512L12.2313 3.05512L14.1319 1.14512C14.2319 1.04512 14.362 0.995117 14.482 0.995117C14.6121 0.995117 14.7421 1.04512 14.8421 1.14512C15.0422 1.33512 15.0422 1.65512 14.8421 1.85512Z" />
    </svg>
  );
}
