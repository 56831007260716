import type { SVGProps, ReactElement } from 'react';

export function CheckmarkOutlinedCheckboxIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M7.81739 1.28368c.24348.24348.24348.63823 0 .88171l-4.55076 4.5508c-.24347.24348-.63823.24348-.88171.00001L.182613 4.51393c-.2434807-.24348-.2434844-.63823-.000008-.88171.243476-.24348.638232-.24349.881715-.00001l1.76145 1.76142 4.10991-4.10995c.24347-.24347.63823-.24348.88171 0Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
