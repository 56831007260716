import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { toDataURL } from 'qrcode';

export interface QrCodeProps {
  url?: string;
  fallbackSrc?: string;
}

export function QrCode({ url, fallbackSrc }: QrCodeProps): ReactNode {
  const [src, setSrc] = useState(fallbackSrc);

  useEffect(() => {
    if (url) {
      void toDataURL(url, {
        type: 'image/png',
        width: 160,
        margin: 0,
        color: {
          dark: '#000000FF',
          light: '#00000000',
        },
      }).then(dataURL => {
        setSrc(dataURL);
      });
    }
  }, [url]);

  return <img alt="" data-test-qr-code src={src} />;
}
