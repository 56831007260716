import type { SVGProps, ReactElement } from 'react';

export function CloseIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={12} width={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path
          clipRule="evenodd"
          d="M6 0C2.685 0 0 2.685 0 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6Zm2.16 7.605a.39.39 0 0 1 0 .547.38.38 0 0 1-.54 0L6.008 6.54l-1.62 1.612a.38.38 0 0 1-.54 0 .39.39 0 0 1 0-.547L5.46 6 3.848 4.395a.38.38 0 0 1 0-.54.38.38 0 0 1 .54 0l1.62 1.605L7.62 3.855a.38.38 0 0 1 .54 0 .38.38 0 0 1 0 .54L6.548 6 8.16 7.605Z"
          fill="#999896"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h12v12H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
