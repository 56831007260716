import type { ReactElement, SVGProps } from 'react';

export function IconPenOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.32 1.01 15 3.69 4.97 13.71 1.01 15l1.28-3.96L12.32 1.01Zm0-1.01c-.26 0-.52.1-.71.29L1.59 10.31s-.08.07-.11.12c-.06.08-.12.18-.15.29L.05 14.69C-.17 15.36.35 16 1 16c.1 0 .21-.02.31-.05l3.97-1.28c.16-.05.3-.14.41-.26L15.71 4.4c.39-.4.39-1.03 0-1.43L13.03.29c-.2-.2-.45-.29-.71-.29Z" />
    </svg>
  );
}
