import type { ReactElement } from 'react';
import cx from 'clsx';
import type { TextAreaProps as AriaTextAreaProps } from 'react-aria-components';
import { TextArea as AriaTextArea } from 'react-aria-components';
import inputStyles from '../input/input.strict-module.css';
import styles from './text-area.strict-module.css';

interface TextAreaProps extends AriaTextAreaProps {
  isExpandable?: boolean;
  isResizable?: boolean;
}

export function TextArea({
  className,
  isExpandable,
  isResizable,
  ...props
}: TextAreaProps): ReactElement {
  return (
    <AriaTextArea
      className={cx(
        className,
        inputStyles['input-field'],
        styles.textarea,
        !isResizable && styles.fixed,
        isExpandable && styles.expandable
      )}
      {...props}
    />
  );
}
