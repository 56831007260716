import type { ReactElement, ReactNode } from 'react';
import { createElement } from 'react';
import cx from 'clsx';
import styles from './disclaimer.strict-module.css';
import { InfoIcon } from './icons/info';
import { WarningIcon } from './icons/warning';
import { ErrorIcon } from './icons/error';

export interface DisclaimerProps {
  children: ReactNode;
  level?: 'info' | 'warning' | 'error';
  block?: boolean;
  className?: string;
}

// TODO: change alt to translation when i18n
const ICONS = {
  info: { element: InfoIcon, alt: 'Info' },
  warning: { element: WarningIcon, alt: 'Warning' },
  error: { element: ErrorIcon, alt: 'Error' },
};

function DisclaimerComponent({
  children,
  level = 'info',
  block = false,
  className,
  ...props
}: DisclaimerProps): ReactElement {
  return (
    <div
      className={cx(
        styles.wrapper,
        block && styles.block,
        !block && styles.inline,
        block && styles[`${level}Block`],
        className
      )}
      data-test-level={level}
      {...props}
    >
      <div className={cx(styles.icon, styles[`${level}Icon`])}>
        {createElement(ICONS[level].element, {
          'aria-label': ICONS[level].alt,
        })}
      </div>
      <p className={cx('body-1', styles.message)} data-test-message>
        {children}
      </p>
    </div>
  );
}

function DisclaimerInline({
  level = 'info',
  className,
  children,
  ...props
}: DisclaimerProps): ReactElement {
  return (
    <DisclaimerComponent
      block={false}
      className={className}
      data-test-disclaimer-inline
      level={level}
      {...props}
    >
      {children}
    </DisclaimerComponent>
  );
}

function DisclaimerBlock({
  level = 'info',
  className,
  children,
  ...props
}: DisclaimerProps): ReactElement {
  return (
    <DisclaimerComponent
      block
      className={className}
      data-test-disclaimer-block
      level={level}
      {...props}
    >
      {children}
    </DisclaimerComponent>
  );
}

export const Disclaimer = {
  Inline: DisclaimerInline,
  Block: DisclaimerBlock,
};
