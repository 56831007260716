import type { ReactElement, SVGProps } from 'react';

export function IconArrowSmallBottomFilled(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m11.85 6.614-3.322 3.415a.749.749 0 0 1-1.059 0L4.15 6.614a.5.5 0 0 1 0-.715.5.5 0 0 1 .352-.146l6.994-.001a.499.499 0 0 1 .354.147.5.5 0 0 1 0 .715Z"
        fill="currentColor"
      />
    </svg>
  );
}
