import type { ReactElement, SVGProps } from 'react';
import type { LocaleCode } from '@repo/shared-config/constants/locales';
import { IconIsometricFlagDe } from './icon-isometric-flag-de';
import { IconIsometricFlagEn } from './icon-isometric-flag-en';
import { IconIsometricFlagEs } from './icon-isometric-flag-es';
import { IconIsometricFlagFr } from './icon-isometric-flag-fr';
import { IconIsometricFlagIt } from './icon-isometric-flag-it';

interface IconIsometricFlagProps extends SVGProps<SVGSVGElement> {
  locale: LocaleCode;
}

export function IconIsometricFlag({
  locale,
  ...props
}: IconIsometricFlagProps): ReactElement | null {
  switch (locale) {
    case 'de':
      return <IconIsometricFlagDe {...props} />;
    case 'en':
      return <IconIsometricFlagEn {...props} />;
    case 'es':
      return <IconIsometricFlagEs {...props} />;
    case 'fr':
      return <IconIsometricFlagFr {...props} />;
    case 'it':
      return <IconIsometricFlagIt {...props} />;
    default:
      return null;
  }
}
