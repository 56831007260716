import type { ReactNode } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { Button, Checkbox, LottiePlayer, WarningSign } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { BackButtonSimple } from '../../shared/back-button-simple';
import lottieData from '../../../lotties/getstarted_kyc_fourthline.json';
import { Sign, Check } from '../../svgs';
import styles from './reassurance.strict-module.css';

export interface ReassuranceProps {
  isKycRequired: boolean;
  isQesRequired: boolean;
  mode: string;
  onContinue: () => void;
  legalCountry: string;
  onBack?: () => void;
}

export function Reassurance({
  isKycRequired,
  isQesRequired,
  legalCountry,
  onContinue,
  onBack,
  mode,
}: ReassuranceProps): ReactNode {
  const [checked, setChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const segment = useSegment();
  const intl = useIntl();
  const { titleId, subtitleId } = (() => {
    if (isKycRequired && isQesRequired) {
      return {
        titleId: 'fourthline.reassurance.kyc-and-qes.title',
        subtitleId: 'fourthline.reassurance.kyc-and-qes.subtitle',
      };
    }

    if (isKycRequired) {
      return {
        titleId: 'fourthline.reassurance.kyc.title',
        subtitleId: 'fourthline.reassurance.kyc.subtitle',
      };
    }

    return {
      titleId: 'fourthline.reassurance.qes.title',
      subtitleId: 'fourthline.reassurance.qes.subtitle',
    };
  })();

  const handleSubmit = (): void => {
    if (!checked) {
      setShowError(true);
      return;
    }
    onContinue();
  };

  const bulletPointsList = (() => {
    const list = [
      intl.formatMessage({ id: 'fourthline.reassurance.requirement.network' }),
      intl.formatMessage({ id: 'fourthline.reassurance.requirement.phone' }),
    ];

    if (isKycRequired) {
      return [
        intl.formatMessage({ id: 'fourthline.reassurance.requirement.id' }, { legalCountry }),
        ...list,
      ];
    }

    return list;
  })();

  const Illustration =
    !isKycRequired && isQesRequired ? (
      <Sign height={200} width={200} />
    ) : (
      <LottiePlayer animationData={lottieData} />
    );

  return (
    <div data-test-reassurance>
      <header className={styles.header}>
        <div className="mb-32" data-test-icon>
          {Illustration}
        </div>
        <h2 className="title-2">
          <FormattedMessage id={titleId} />
        </h2>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions -- TODO solve later */}
        <p
          className="body-2"
          dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: subtitleId }) }}
          data-test-agreement-subtitle
          onClick={() => {
            segment.track(`${mode}_fourthline_website_link_clicked`);
          }}
        />
      </header>
      <ul className={cx('mb-24', styles.bulletPointsList)} data-test-bulletpoints>
        {bulletPointsList.map(message => (
          <li key={message}>
            <Check />
            <span className="body-2">{message}</span>
          </li>
        ))}
      </ul>
      {/* there's an ongoing issue with checkbox validation is not removing the error when it should but a fix is ready here: https://github.com/adobe/react-spectrum/pull/6079 */}
      <div className="mb-32">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control --
            There are clickable links within the label */}
        <label
          className={styles.checkboxContainer}
          htmlFor="fourthline-terms-privacy-policy-checkbox"
        >
          <Checkbox
            data-test-agreement-checkbox
            id="fourthline-terms-privacy-policy-checkbox"
            onChange={setChecked}
          />
          <span
            className={cx('body-2', styles.label)}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'fourthline.reassurance.terms.label' }),
            }}
            data-testid="label"
          />
        </label>
        {!checked && showError ? (
          <div className={styles.error} data-test-error-label="" role="alert">
            <WarningSign className="mr-8" />
            <FormattedMessage id="fourthline.reassurance.terms.error" />
          </div>
        ) : null}
      </div>
      <Button data-test-agreement-submit onPress={handleSubmit} stretch type="submit">
        <FormattedMessage id="btn.continue" />
      </Button>
      {onBack ? (
        <div className={styles.backButtonContainer}>
          <BackButtonSimple data-test-go-back onPress={onBack} />
        </div>
      ) : null}
    </div>
  );
}
