import cx from 'clsx';
import type { ReactElement } from 'react';
import type { ButtonProps as AriaButtonProps } from 'react-aria-components';
import { isValidElement } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { IconChevronRightOutlined } from '../../assets/icons';
import { SkeletonLoader } from '../skeleton-loader';
import styles from './selector.strict-module.css';

export interface SelectorProps extends AriaButtonProps {
  icon?: ReactElement;
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  content?: string | ReactElement;
  isLoading?: boolean;
}

/**
 * This component is an incomplete implementation of the Qonto Selector component.
 * Though it adheres to the Qonto Selector API, it is not a full implementation.
 * It was agreed in this Slack thread (https://qonto.slack.com/archives/C06UZ4JKVHU/p1732540595204029).
 */
export function Selector({
  isDisabled = false,
  isLoading = false,
  className,
  icon,
  title,
  subtitle,
  content,
  onPress,
  ...props
}: SelectorProps): ReactElement {
  return (
    <AriaButton
      className={cx(styles.selector, styles.classic, isDisabled && styles.disabled, className)}
      isDisabled={isDisabled}
      onPress={onPress}
      type="button"
      {...props}
      data-test-selector
    >
      {isLoading ? (
        <>
          <SkeletonLoader.Avatar className={styles.icon} data-test-selector-icon-loading />
          <div className={styles.header}>
            <SkeletonLoader.Line className="mb-8" data-test-selector-line-loading width="90px" />
            <SkeletonLoader.Line className="mb-8" data-test-selector-line-loading width="156px" />
          </div>
          <div className={styles['action-icon']}>
            <IconChevronRightOutlined />
          </div>
        </>
      ) : (
        <>
          {icon ? (
            <div className={styles.icon} data-test-selector-icon>
              {icon}
            </div>
          ) : null}
          <div className={styles.header}>
            {title ? (
              <span className={cx('body-1', styles.title)} data-test-selector-title>
                {title}
              </span>
            ) : null}
            {subtitle ? (
              <span className={cx('body-2', styles.subtitle)} data-test-selector-subtitle>
                {subtitle}
              </span>
            ) : null}
          </div>
          {content ? (
            <div className={cx(styles.content, 'ml-24')} data-test-selector-content>
              {isValidElement(content) ? content : <span className="body-2">{content}</span>}
            </div>
          ) : null}
          <div className={styles['action-icon']}>
            <IconChevronRightOutlined />
          </div>
        </>
      )}
    </AriaButton>
  );
}
