import cx from 'clsx';
import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import styles from './skeleton-loader.strict-module.css';

interface HeaderProps extends ComponentPropsWithoutRef<'div'> {
  width?: string;
  lines?: number;
  smallLinesWidth?: string;
  className?: string;
}

function Header({
  width,
  lines = 0,
  smallLinesWidth = '75%',
  className,
  ...props
}: HeaderProps): ReactElement {
  return (
    <div className={cx(styles.header, className)} {...props}>
      <SkeletonLoader.Line data-test-placeholder-header-first-line standalone width={width} />
      {lines > 0 &&
        Array.from({ length: lines }, (_, index) => (
          <SkeletonLoader.Line
            data-test-placeholder-header-extra-line
            key={index}
            width={smallLinesWidth}
          />
        ))}
    </div>
  );
}

interface SkeletonLoaderWrapperProps extends ComponentPropsWithoutRef<'div'> {
  standalone?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
  lines?: number;
  smallLinesWidth?: string;
  className?: string;
}

export interface SkeletonLoaderProps extends SkeletonLoaderWrapperProps {
  type?: 'avatar' | 'block' | 'line' | 'header';
}

export function SkeletonLoaderComponent({
  type = 'block',
  standalone = false,
  width,
  height,
  borderRadius,
  lines,
  smallLinesWidth,
  className,
  ...props
}: SkeletonLoaderProps): ReactElement {
  if (type === 'header') {
    return (
      <Header
        className={className}
        lines={lines}
        smallLinesWidth={smallLinesWidth}
        width={width}
        {...props}
      />
    );
  }

  return (
    <div
      className={cx(styles.base, styles[type], standalone && styles.standalone, className)}
      style={{
        width,
        height,
        borderRadius,
      }}
      {...props}
    />
  );
}

function SkeletonLoaderLine({ ...props }: SkeletonLoaderWrapperProps): ReactElement {
  return <SkeletonLoaderComponent type="line" {...props} />;
}

function SkeletonLoaderBlock({ ...props }: SkeletonLoaderWrapperProps): ReactElement {
  return <SkeletonLoaderComponent type="block" {...props} />;
}

function SkeletonLoaderHeader({ ...props }: SkeletonLoaderWrapperProps): ReactElement {
  return <SkeletonLoaderComponent type="header" {...props} />;
}

function SkeletonLoaderAvatar({ ...props }: SkeletonLoaderWrapperProps): ReactElement {
  return <SkeletonLoaderComponent type="avatar" {...props} />;
}

export const SkeletonLoader = {
  Line: SkeletonLoaderLine,
  Block: SkeletonLoaderBlock,
  Header: SkeletonLoaderHeader,
  Avatar: SkeletonLoaderAvatar,
};
