import type { ReactElement, ComponentPropsWithoutRef } from 'react';
import cx from 'clsx';
import { IconCheckmarkRoundedFilledSmall } from '../../assets/icons/icon-checkmark-rounded-filled-small';
import { IconClockFilledSmall } from '../../assets/icons/icon-clock-filled-small';
import { IconErrorFilledSmall } from '../../assets/icons/icon-error-filled-small';
import { IconWarningSmall } from '../../assets/icons/icon-warning-small';
import styles from './tag.strict-module.css';

interface TagProps extends ComponentPropsWithoutRef<'span'> {
  color?: 'gray' | 'red' | 'green' | 'orange' | 'purple';
  label: string;
  className?: string;
}

const ICONS = {
  gray: null,
  red: <IconErrorFilledSmall />,
  green: <IconCheckmarkRoundedFilledSmall />,
  purple: <IconClockFilledSmall />,
  orange: <IconWarningSmall />,
} as const;

// This component is deprecated, please use <BadgeStatus /> instead
export function Tag({ color = 'gray', label, className, ...props }: TagProps): ReactElement {
  return (
    <span
      className={cx(styles.tag, color === 'gray' && styles[color], className)}
      data-test-color={color}
      data-test-status-tag=""
      {...props}
    >
      {Boolean(ICONS[color]) && (
        <span className={cx(styles.icon, styles[color])}>{ICONS[color]}</span>
      )}
      {label}
    </span>
  );
}
