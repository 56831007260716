import { useRadioGroupState } from 'react-stately';
import {
  RadioGroupStateContext,
  LabelContext,
  FieldErrorContext,
  Provider,
} from 'react-aria-components';
import type { RadioProps } from 'react-aria-components';
import { useRadioGroup } from 'react-aria';
import type { ReactElement } from 'react';
import {} from 'react';
import { RadioButton } from '../radio-button';

/** This has both props for a RadioButton and some from the Radio Group context */
export type RadioButtonControlledProps = RadioProps & {
  name: string;
  groupValue: string;
  onChange: (value: string) => void;
};

/**
 * RadioButtonControlled is a wrapper around RadioButton that provides a controlled state for the radio button.
 * It is used to help migrate to the existing usage of FormElements::RadioButton components.
 * It implmentations relies on the current implementation of the RadioGroup component.
 * @deprecated use RadioGroup & RadioButton instead if your creating a new component.
 */
export function RadioButtonControlled({
  groupValue,
  onChange,
  ...props
}: RadioButtonControlledProps): ReactElement {
  const state = useRadioGroupState({
    value: groupValue,
    onChange,
    validationBehavior: 'native',
  });

  // We need to set aria-label to any value to avoid a warning looking for a label for a radio group that doesn't exist in the DOM
  const { labelProps, ...validation } = useRadioGroup({ ...props, 'aria-label': 'none' }, state);

  return (
    <Provider
      values={[
        [RadioGroupStateContext, state],
        [LabelContext, { ...labelProps, elementType: 'span' }],
        [FieldErrorContext, validation],
      ]}
    >
      <RadioButton {...props} />
    </Provider>
  );
}
