import type { ReactElement, SVGProps } from 'react';

export function IconCardOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.5 12h-2c-.86 0-1.5-.64-1.5-1.5S9.64 9 10.5 9h2c.86 0 1.5.64 1.5 1.5s-.64 1.5-1.5 1.5Zm-2-2c-.37 0-.5.26-.5.5s.13.5.5.5h2c.37 0 .5-.26.5-.5s-.13-.5-.5-.5h-2Z" />
      <path d="M14.8 2H1.2C.54 2 0 2.53 0 3.2v9.6c0 .66.54 1.2 1.2 1.2h13.6c.66 0 1.2-.54 1.2-1.2V3.2c0-.67-.54-1.2-1.2-1.2Zm.2 10.8c0 .1-.09.2-.2.2H1.2c-.11 0-.2-.1-.2-.2V6h14v6.8ZM1 5V3.2c0-.11.09-.2.2-.2h13.6c.11 0 .2.09.2.2V5H1Z" />
    </svg>
  );
}
