import cx from 'clsx';
import type { ReactNode, ComponentPropsWithoutRef } from 'react';
import styles from './steps.strict-module.css';

export interface StepsProps extends ComponentPropsWithoutRef<'ul'> {
  steps: string[];
  className?: string;
}

export function Steps({ steps, className, ...props }: StepsProps): ReactNode {
  return (
    <ul className={cx(className, styles.steps)} {...props}>
      {steps.map(step => (
        <li className={cx('body-2', styles.step)} key={step}>
          {step}
        </li>
      ))}
    </ul>
  );
}
