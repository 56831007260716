import type { ReactElement, ReactNode } from "react";
import { TabPanel as AriaTabPanel } from "react-aria-components";
import styles from "./tab-panel.strict-module.css";

export interface TabProps {
  children?: ReactNode;
  tabId: string;
}

export function TabPanel({ children, tabId }: TabProps): ReactElement {
  return (
    <AriaTabPanel className={styles["tab-panel"]} id={tabId}>
      {children}
    </AriaTabPanel>
  );
}
