import type { SVGProps, ReactNode } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export function SvgSign({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): ReactNode {
  return (
    <svg
      aria-labelledby={titleId}
      fill="none"
      height={150}
      viewBox="0 0 150 150"
      width={150}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M57.8 110.27 115.6 140V43.05L57.8 9.67l-1.15 7.38 1.15 7.39z" fill="#1D1D1B" />
      <path
        d="M57.8 110.27 115.6 140V43.05L57.8 9.67l-1.15 7.38 1.15 7.39z"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth={0.33}
      />
      <path
        d="M56.64 83.35c0 7.33-1.1 13.18-4.33 17.32s-6.56 4.14-6.56 4.14l54.37 31.95s4.1 0 8.2-4.1 6.14-9.12 6.14-16.45V44.16L56.64 10.8v72.56Z"
        fill="#fff"
      />
      <path
        d="M56.64 83.35c0 7.33-1.1 13.18-4.33 17.32s-6.56 4.14-6.56 4.14l54.37 31.95s4.1 0 8.2-4.1 6.14-9.12 6.14-16.45V44.16L56.64 10.8v72.56Z"
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth={0.33}
      />
      <rect
        fill="#DFF9F0"
        height={7.95}
        rx={1.5}
        stroke="#1D1D1B"
        strokeLinejoin="round"
        strokeWidth={0.33}
        transform="matrix(.87 .5 0 1 67.5 31.63)"
        width={38.97}
      />
      <path
        d="M67.57 55.22 101.6 74.8M67.57 64.74 101.6 84.3m-34.02-9.78L101.6 94.1"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.33}
      />
      <path
        d="M0 0h24.25v12.83H0z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeWidth={0.33}
        transform="matrix(.87 .5 0 1 80.25 91.53)"
      />
      <path
        d="M0 0h31.39v16.61H0z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeWidth={0.33}
        transform="matrix(.87 .5 0 1 74.25 84.75)"
      />
      <path
        d="M0 0h31.39v16.61H0z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeWidth={0.33}
        transform="matrix(.87 .5 0 1 67.5 88.5)"
      />
      <path
        d="M74.94 107.49c.04-.33.8-11.7 1.88-10.63.38.38.83 1.42 1.15 3.07.22 1.16.3 2.06.38 3.15.05.86.3-.82.36-1.61.03-.36.1-1.42.12-1.05.03 1.36.04 2.7.05 4.05v2.42c0 .16-.1.4.05.51.15.12.19-.19.28-.29.52-.6 1.1-1.04 1.8-1.34a10.6 10.6 0 0 1 5.69-.7c.98.14 3.12.68 3.29 2.65.17 2.08-1.56 2.3-2.97 1.9-1.35-.36-2.81-1.24-4.18-2.04-1.96-1.15-3.91-2.4-5.86-3.68"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeWidth={0.66}
      />
      <path
        d="m50.34 93.29-1.07-1.18-15.88-8.5.3-.34-1.48-1.08-.54 1.57-.15.93z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="M50.7 93.1c-.33.62-1.48 1.05-2.1.71L31.52 84.7l1.44-1.1z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="M72.1 101.63c.5.26 2.01.64 2.27.15.27-.49-.83-1.65-1.32-1.92a1 1 0 1 0-.95 1.77"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="M63.5 99.78c-2.58-1.35.86-7.99 3.68-6.36 2.81 1.63 5.92 5.85 5.92 5.85v.01c.38.43.45 1 .06 1.72s-1.48.9-1.48.9v-.01s-5.61-.75-8.19-2.1Z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="m57.1 96.43 3.53-6.54 6.6 3.52.02.01c1.06.57 1.12 2.5.14 4.3-.97 1.8-2.61 2.81-3.66 2.24l-.03-.01z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="M57.12 96.44c1.06.56 2.7-.44 3.67-2.25.97-1.8.9-3.73-.14-4.3-1.05-.56-2.7.45-3.67 2.25-.98 1.8-.91 3.73.14 4.3"
        fill="#fff"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="m33.05 83.51 3.52-6.55 24.11 13 .03.01c1.05.57 1.12 2.5.14 4.3-.97 1.8-2.61 2.81-3.67 2.24l-.02-.01-24.11-13Z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="M33.06 83.51c1.06.57 2.7-.44 3.67-2.24s.9-3.73-.14-4.3c-1.05-.56-2.7.44-3.67 2.25-.97 1.8-.91 3.73.14 4.3Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="m29.2 80.68 2.99-5.56 3.74 2.08h.02c.9.49.95 2.12.12 3.65-.82 1.54-2.21 2.39-3.1 1.9h-.03z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="M29.2 80.68c.9.48 2.3-.37 3.12-1.9.83-1.53.77-3.17-.12-3.65-.9-.48-2.29.38-3.11 1.9-.83 1.54-.77 3.17.12 3.65Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
      <path
        d="M32.2 78.72c.75-1.4.77-2.88.1-3.5l.05.03c.9.48.95 2.12.13 3.65-.83 1.53-2.22 2.38-3.12 1.9-.07-.04-.14-.1-.21-.16.89.43 2.24-.42 3.05-1.92"
        fill="#fff"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.33}
      />
    </svg>
  );
}
