import type { ReactElement, SVGProps } from 'react';

export function IconPlusOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15 8.01c0 .28-.22.5-.5.5H8.49v5.99c0 .28-.23.5-.5.5s-.5-.22-.5-.5V8.51H1.5a.5.5 0 0 1-.01-1h6V1.5c0-.28.22-.5.5-.5s.5.22.5.5v6.01h6c.3-.03.51.22.51.5Z" />
    </svg>
  );
}
