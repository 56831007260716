import type { ReactElement } from 'react';
import { Tab as AriaTab } from 'react-aria-components';
import cx from 'clsx';
import styles from './tab.strict-module.css';

export interface TabProps {
	id: string;
	icon?: ReactElement;
	title: string;
	count?: number;
	hasNotifications?: boolean;
	hasDivider?: boolean;
	'data-test-tabs-nav-item': number;
	'data-test-tabs-nav-item-value': string;
}

export function Tab({
	id,
	icon,
	title,
	hasNotifications,
	hasDivider = false,
	count = 0,
	...props
}: TabProps): ReactElement {
	const hasCount = !icon && count > 0;
	return (
		<AriaTab
			className={cx(styles.tab, icon && styles['has-icon'], hasDivider && styles['with-divider'])}
			id={id}
			{...props}
		>
			{icon}
			{icon && hasNotifications ? (
				<span className={styles['notification-dot']} data-testid="notification-dot" />
			) : null}
			{title}
			{hasCount ? (
				<span className="caption-bold ml-8" data-testid="counter">
					{count}
				</span>
			) : null}
		</AriaTab>
	);
}
