import type { SVGProps, ReactElement } from 'react';

export function SearchIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={12} width={12} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path
          clipRule="evenodd"
          d="M11.8875 11.22L8.775 8.10001C9.4425 7.26001 9.84 6.20251 9.84 5.05501C9.84 2.33251 7.635 0.13501 4.92 0.13501C2.205 0.13501 0 2.33251 0 5.05501C0 7.77751 2.205 9.97501 4.92 9.97501C6.21 9.97501 7.38 9.48001 8.2575 8.66251L11.355 11.7525C11.505 11.9025 11.745 11.9025 11.8875 11.7525C12.0375 11.61 12.0375 11.37 11.8875 11.22ZM4.92 9.21751C2.6175 9.21751 0.7575 7.35001 0.7575 5.05501C0.7575 2.76001 2.6175 0.89251 4.92 0.89251C7.2225 0.89251 9.0825 2.75251 9.0825 5.05501C9.0825 7.35751 7.2225 9.21751 4.92 9.21751Z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
