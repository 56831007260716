import * as React from 'react';
import { clsx } from 'clsx';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import styles from './subscription-error.strict-module.css';

interface SubscriptionErrorProps {
  title: string;
  subtitle: string;
  ctaText: string;
  onClick: () => void;
  lottie: object;
}

export function SubscriptionError({
  title,
  subtitle,
  ctaText,
  lottie,
  onClick,
}: SubscriptionErrorProps): React.JSX.Element {
  return (
    <div className={styles.container} data-test-subscription-error>
      <LottiePlayer
        animationData={lottie}
        className={styles.lottie}
        data-test-subscription-error-lottie
        loop={false}
      />
      <h1 className={clsx(styles.title, 'title-2')} data-test-subscription-error-title>
        {title}
      </h1>
      <p className={clsx(styles.subtitle, 'body-1')} data-test-subscription-error-subtitle>
        {subtitle}
      </p>
      <Button data-test-subscription-error-button onPress={onClick}>
        {ctaText}
      </Button>
    </div>
  );
}
