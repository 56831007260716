import cx from 'clsx';
import type { ReactElement } from 'react';
import type {
  DateFieldProps as AriaDateFieldProps,
  DateValue,
  ValidationResult,
} from 'react-aria-components';
import { DateField as AriaDateField, DateSegment } from 'react-aria-components';
import { DateInput, Description, Error, Label } from '../form-elements';
import styles from './date-field.strict-module.css';
import { useDateFieldValidation } from './use-date-field-validation';

export interface DateFieldProps<T extends DateValue> extends AriaDateFieldProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string;
  onChange?: (value: DateValue, isInvalid?: boolean) => void;
  customValidationMessages?: { [key in keyof ValidationResult['validationDetails']]?: string };
}

export function DateField<T extends DateValue>({
  label,
  description,
  errorMessage,
  className,
  isReadOnly,
  onChange,
  customValidationMessages = {},
  ...props
}: DateFieldProps<T>): ReactElement {
  const { error, isInvalid, handleChange } = useDateFieldValidation({
    value: props.value || props.defaultValue,
    onChange,
    errorMessage,
    minValue: props.minValue,
    maxValue: props.maxValue,
    customValidationMessages,
    isRequired: props.isRequired,
  });

  return (
    <AriaDateField
      className={cx(className, styles.container)}
      isReadOnly={isReadOnly}
      {...props}
      isInvalid={isInvalid}
      onChange={handleChange}
    >
      {Boolean(label) && <Label>{label}</Label>}
      <DateInput className={cx(isReadOnly && styles.readonly)} data-test-date-field-input>
        {segment => <DateSegment segment={segment} />}
      </DateInput>
      <Error>{error}</Error>
      {Boolean(description) && <Description>{description}</Description>}
    </AriaDateField>
  );
}
