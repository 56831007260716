import { useEventListener } from 'usehooks-ts';
import { useRef } from 'react';
import type { Locale } from '../../../utils/locale';
import { useMeta } from './use-meta';

interface DesktopEvents {
  onOnboardingStarted?: (event: CustomEvent) => void;
  onLoaded?: (event: CustomEvent) => void;
  onOnboardingContinuing?: (event: CustomEvent) => void;
  onOnboardingCompleted?: (event: CustomEvent) => void;
  onOnboardingCompletedError?: (event: CustomEvent) => void;
  onOnboardingRestartRequest: (webSdkElement: Element) => Promise<void>;
}

type ContinueMobileRequestEvent = CustomEvent<
  (redirectHandlerArgs: { redirect: string; redirectFailure: string }) => void
>;

interface MobileEvents {
  onContinueMobileRequest?: (event: ContinueMobileRequestEvent) => void;
}

interface Events {
  desktop: DesktopEvents;
  mobile?: MobileEvents;
}

export interface EmbeddedWebsdkProps {
  token: string;
  locale: Locale;
  visible: boolean;
  events: Events;
}

declare global {
  interface DocumentEventMap {
    flOnboardingRestartRequest: CustomEvent;
    flOnboardingStatus: CustomEvent<
      | 'OnboardingStarted'
      | 'Loaded'
      | 'OnboardingContinuing'
      | 'OnboardingCompleted'
      | 'OnboardingCompletedError'
    >;
    flContinueMobileRequest: ContinueMobileRequestEvent;
  }
}

export function EmbeddedWebsdk({
  token,
  locale,
  visible,
  events,
}: EmbeddedWebsdkProps): JSX.Element {
  useMeta();
  const documentRef = useRef<Document>(document);

  useEventListener(
    'flOnboardingStatus',
    event => {
      if (event.detail === 'OnboardingStarted') {
        events.desktop.onOnboardingStarted?.(event);
      } else if (event.detail === 'Loaded') {
        events.desktop.onLoaded?.(event);
      } else if (event.detail === 'OnboardingContinuing') {
        events.desktop.onOnboardingContinuing?.(event);
      } else if (event.detail === 'OnboardingCompleted') {
        events.desktop.onOnboardingCompleted?.(event);
      } else {
        events.desktop.onOnboardingCompletedError?.(event);
      }
    },
    documentRef
  );

  useEventListener(
    'flOnboardingRestartRequest',
    (): void => {
      const element = document.querySelector('fl-flow-onboarding');
      if (element) {
        void events.desktop.onOnboardingRestartRequest(element);
      }
    },
    documentRef
  );

  useEventListener(
    'flContinueMobileRequest',
    event => {
      events.mobile?.onContinueMobileRequest?.(event);
    },
    documentRef
  );

  return (
    <div data-test-fourthline-embedded-websdk style={{ display: visible ? 'block' : 'none' }}>
      <fl-flow-onboarding locale={locale} token={token} />
    </div>
  );
}
