import type { ReactElement, SVGProps } from 'react';

export function IconLockOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path
          clipRule="evenodd"
          d="M4 6V4.17C4 2.08 5.53.22 7.61.02A3.993 3.993 0 0 1 12 4v2h1c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1H2.82c-.45 0-.82-.37-.82-.82V6.82c0-.45.37-.82.82-.82H4Zm1 0h6V4c0-1.66-1.34-3-3-3S5 2.34 5 4v2Zm8 9H3V7h10v8Z"
          fillRule="evenodd"
        />
        <path d="M8 13c-.28 0-.5-.22-.5-.5v-2c0-.28.22-.5.5-.5s.5.22.5.5v2c0 .28-.22.5-.5.5Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
