import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styles from './empty-states-divider.strict-module.css';

export function EmptyStatesDivider(): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <span className={styles.divider} data-test-empty-state-divider>
      {formatMessage({ id: 'empty-state-discover-divider' })}
    </span>
  );
}
