import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import type { ButtonProps as AriaButtonProps } from 'react-aria-components';
import { Button } from '@repo/design-system-kit';

export interface BackButtonSimpleProps extends AriaButtonProps {
  onPress: () => void;
}

export function BackButtonSimple({ onPress, ...props }: BackButtonSimpleProps): ReactNode {
  const intl = useIntl();
  const text = intl.formatMessage({ id: 'btn.back' });

  return (
    <Button aria-label={text} onPress={onPress} type="button" variant="tertiary" {...props}>
      <svg
        className="mr-8"
        fill="currentColor"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M8.02 1.98a.5.5 0 010 .707L3.207 7.5H14a.5.5 0 010 1H3.207l4.813 4.813a.5.5 0 11-.707.707L1.646 8.354a.5.5 0 010-.708L7.313 1.98a.5.5 0 01.707 0z"
          fillRule="evenodd"
        />
      </svg>
      {text}
    </Button>
  );
}
