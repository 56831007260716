import type { RadioProps } from 'react-aria-components';
import { Radio } from 'react-aria-components';
import type { ReactElement } from 'react';
import cx from 'clsx';
import styles from './radio-button.strict-module.css';

export type RadioButtonProps<T extends string> = Omit<RadioProps, 'value'> & {
  value: T;
};

export function RadioButton<T extends string>({
  className,
  ...props
}: RadioButtonProps<T>): ReactElement {
  return (
    <Radio className={cx(styles.radio, 'body-1', className)} {...props} data-test-radio-button />
  );
}
