import type { ReactElement, SVGProps } from 'react';

export function WarningSign(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_23210_18862)">
        <path
          clipRule="evenodd"
          d="M15.8516 13.4225L8.95019 1.48249C8.5301 0.74249 7.4699 0.74249 7.04981 1.48249L0.148445 13.4225C-0.271639 14.1625 0.248464 15.0725 1.09863 15.0725H14.9014C15.7515 15.0725 16.2716 14.1625 15.8516 13.4225ZM7.4999 5.77249C7.4999 5.50249 7.71994 5.27249 8 5.27249C8.28006 5.27249 8.5001 5.50249 8.5001 5.77249V10.3325C8.5001 10.6025 8.28006 10.8325 8 10.8325C7.71994 10.8325 7.4999 10.6025 7.4999 10.3325V5.77249ZM8 13.0925C7.63993 13.0925 7.34987 12.8025 7.34987 12.4425C7.34987 12.0825 7.63993 11.7925 8 11.7925C8.36007 11.7925 8.65013 12.0825 8.65013 12.4425C8.65013 12.8025 8.36007 13.0925 8 13.0925Z"
          fill="#F95656"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_23210_18862">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
}
