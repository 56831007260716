import type { ReactElement, SVGProps } from 'react';

export function IconArrowSmallTopFilled(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m4.15 9.386 3.322-3.415a.749.749 0 0 1 1.059 0l3.319 3.415a.5.5 0 0 1 0 .715.499.499 0 0 1-.352.146l-6.994.001a.5.5 0 0 1-.465-.31.5.5 0 0 1 .111-.552Z" />
    </svg>
  );
}
