import type { SVGProps, ReactElement } from 'react';

export function IconClockOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.004 9h4.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-3.5V3.51c0-.28-.22-.5-.5-.5s-.5.22-.5.5V9Z" />
      <path
        clipRule="evenodd"
        d="M8.004 16c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8Zm7-8c0 3.87-3.13 7-7 7s-7-3.13-7-7 3.13-7 7-7 7 3.13 7 7Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
