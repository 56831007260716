import * as React from 'react';
import { clsx } from 'clsx';
import { TextWithHighlights } from '../price-plan/text-with-highlights.tsx';
import { BenefitsGroup } from '../price-plan/benefits-group.tsx';
import type { AddonsGroupProps } from '../price-plan/addons-group.tsx';
import { AddonsGroup } from '../price-plan/addons-group.tsx';
import { Divider } from '../divider.tsx';
import styles from './custom-price-plan.strict-module.css';

export interface CustomPricePlanProps {
  addons: AddonsGroupProps;
  mainButton: React.ReactNode;
  benefitsGroup: BenefitsGroupInterface[];
  ctaDescription: string;
  ctaName: string;
  description: string;
  price: string;
  title: string;
}

interface BenefitsGroupInterface {
  title: string;
  benefits: Benefit[];
}

interface Benefit {
  description: string;
  isIncluded: boolean;
}

export function CustomPricePlan({
  addons,
  mainButton,
  benefitsGroup,
  ctaDescription,
  description,
  price,
  title,
}: CustomPricePlanProps): React.ReactNode {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.titleContainer, 'mb-24')}>
        <h2 className={clsx(styles.title, 'title-2')}>{title}</h2>
      </div>
      <div className={clsx(styles.priceContainer, 'mb-24')}>
        <span className="title-2">{price}</span>
      </div>
      <p className={clsx(styles.description, 'body-1 mb-24')}>
        <TextWithHighlights>{description}</TextWithHighlights>
      </p>
      {mainButton}
      <p className={clsx(styles.ctaDescription, 'body-1')}>{ctaDescription}</p>
      {benefitsGroup.map(({ title: groupTitle, benefits }) => (
        <BenefitsGroup benefits={benefits} key={groupTitle} title={groupTitle} />
      ))}
      <div className={styles.bottomSection}>
        <Divider />
        <AddonsGroup addons={addons.addons} title={addons.title} />
      </div>
    </div>
  );
}
