import * as React from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import { clsx } from 'clsx';
import styles from './price-breakdown.strict-module.css';

export function PriceBreakdownLoader({ products = 0 }: { products?: number }): React.JSX.Element {
  return (
    <div className={styles.wrapper} data-test-price-breakdown-loader>
      {products > 0 && (
        <div>
          {Array(products)
            .fill(null)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key -- static array
              <div className={styles.product} key={index}>
                <div className="mb-8">
                  <SkeletonLoader.Line width="144px" />
                </div>

                <div className="mb-8">
                  <SkeletonLoader.Line width="72px" />
                </div>
              </div>
            ))}
        </div>
      )}

      <div className={styles.container}>
        <div>
          <div className="mb-16">
            <SkeletonLoader.Line width="72px" />
          </div>
          <SkeletonLoader.Line width="144px" />
        </div>

        <div className={styles['loader-padding']}>
          <SkeletonLoader.Line width="96px" />
        </div>
      </div>
    </div>
  );
}

export interface PriceBreakdownProps {
  products: { title: string; price: string }[];
  total: string;
  billing: string;
  price: string;
  children?: React.ReactNode;
}

export function PriceBreakdown({
  products,
  total,
  billing,
  price,
  children,
}: PriceBreakdownProps): React.JSX.Element {
  return (
    <div className={styles.wrapper}>
      {products.length > 0 && (
        <div className={styles.products}>
          {products.map((product, index) => (
            <div className={styles.product} key={product.title}>
              <div className="body-1" data-test-price-breakdown-item={index}>
                {product.title}
              </div>
              <div className="body-1" data-test-price-breakdown-item-price={index}>
                {product.price}
              </div>
            </div>
          ))}
        </div>
      )}
      {children}
      <div className={clsx(styles.container, styles['total-padding'])}>
        <div>
          <div className="title-4" data-test-total-title>
            {total}
          </div>
          <div className={clsx(styles['color-secondary'], 'caption')} data-test-total-billing>
            {billing}
          </div>
        </div>
        <div className="title-2" data-test-total-price>
          {price}
        </div>
      </div>
    </div>
  );
}
