import type { ReactElement } from 'react';
import cx from 'clsx';
import type { DateInputProps} from 'react-aria-components';
import { DateInput as AriaDateInput } from 'react-aria-components';
import styles from '../input/input.strict-module.css';
import dateInputStyles from './date-input.strict-module.css';

export function DateInput({ className, ...props }: DateInputProps): ReactElement {
  return (
    <AriaDateInput
      className={cx(dateInputStyles.wrapper, styles['input-field'], className)}
      {...props}
    />
  );
}
