import type { ReactNode } from 'react';
import { useState } from 'react';
import type { PressEvent } from 'react-aria-components';
import { Button as AriaButton } from 'react-aria-components';
import type { AriaButtonProps } from 'react-aria';
import cx from 'clsx';
import { IconChevronBottomOutlined } from '../../assets/icons';
import styles from './data-object-toggle-button.strict-module.css';

export function DataObjectToggleButton({ onPress, children, ...rest }: AriaButtonProps): ReactNode {
  const [isExpanded, setIsExpanded] = useState(false);

  function handleClick(e: PressEvent): void {
    setIsExpanded(!isExpanded);
    onPress?.(e);
  }

  return (
    <AriaButton
      aria-expanded={isExpanded ? 'true' : 'false'}
      className={cx(styles['data-object-trigger'], 'body-2')}
      data-test-trigger
      onPress={e => {
        handleClick(e);
      }}
      type="button"
      {...rest}
    >
      {children}
      <IconChevronBottomOutlined
        aria-hidden="true"
        className={cx(styles.chevron, isExpanded && styles['chevron-down'])}
        data-test-is-icon-open={isExpanded ? 'true' : 'false'}
      />
    </AriaButton>
  );
}
