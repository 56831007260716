import type { SVGProps, ReactNode } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export function SvgIdentity({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps): ReactNode {
  return (
    <svg
      aria-labelledby={titleId}
      fill="none"
      height={116}
      viewBox="0 0 116 116"
      width={116}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M66.47 72.35c.91.53.91 1.39 0 1.92L31.99 94.3c-.9.53-2.39.53-3.3 0L8.52 82.67c-.91-.53-.92-1.38-.01-1.91L43 60.72c.9-.53 2.38-.53 3.29 0l20.17 11.63Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      />
      <path
        d="M66.48 74.27 31.98 94.3c-.9.53-2.38.53-3.3 0L8.53 82.67c-.46-.27-.69-.62-.69-.96v.76c0 .34.23.69.69.95L28.7 95.07c.91.53 2.4.53 3.3 0l34.5-20.04c.45-.27.67-.61.67-.96v-.76c0 .35-.23.7-.68.96Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      />
      <path
        d="M42.55 68.31c.28.16.28.42 0 .58l-8.2 4.77a1.1 1.1 0 0 1-1 0c-.27-.16-.27-.42 0-.58l8.21-4.77c.27-.16.72-.16 1 0Zm15.18 2.29c.27.16.27.42 0 .58l-13.8 8.01a1.1 1.1 0 0 1-.99 0c-.28-.15-.27-.41 0-.57l13.8-8.02c.27-.16.71-.16.99 0m-2.98-1.72c.28.16.28.42 0 .58l-13.8 8.01a1.1 1.1 0 0 1-.98 0c-.28-.15-.28-.41 0-.57l13.8-8.02c.26-.15.7-.16.98 0m-2.98-1.71c.28.15.28.41 0 .57l-13.79 8.02a1.1 1.1 0 0 1-1 0c-.27-.16-.26-.42 0-.58l13.8-8.01c.28-.16.72-.16 1 0Z"
        fill="#1D1D1B"
      />
      <path
        d="M41.47 81c.91.53.91 1.4.01 1.92l-10.84 6.3c-.91.53-2.39.53-3.3 0l-10.91-6.3c-.92-.53-.92-1.38-.01-1.9l10.84-6.31c.9-.53 2.38-.53 3.3 0l10.9 6.3Z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeWidth={0.5}
      />
      <mask
        height={16}
        id="identity_svg__a"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
        width={28}
        x={15}
        y={74}
      >
        <path
          d="M41.47 81c.91.53.91 1.4.01 1.92l-10.84 6.3c-.91.53-2.39.53-3.3 0l-10.91-6.3c-.92-.53-.92-1.38-.01-1.9l10.84-6.31c.9-.53 2.38-.53 3.3 0l10.9 6.3Z"
          fill="#fff"
        />
      </mask>
      <g
        fill="#CEF6E9"
        mask="url(#identity_svg__a)"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      >
        <path d="m41.6 82.73-10.99 6.4c-3-1.82-3.03-4.66-.04-6.4 3-1.73 7.9-1.73 11.04 0ZM30.42 79c1.67.96 1.63 2.57-.11 3.58a6.8 6.8 0 0 1-6.17.08c-1.68-.97-1.64-2.57.1-3.58a6.81 6.81 0 0 1 6.18-.08Z" />
      </g>
      <path
        d="m26.98 32.33 24.76-14.38a5.16 5.16 0 0 1 4.9.15l55.45 31.94.27.21c1.89 1.47 1.63 4.58-.53 5.83l-.05.02-.06.05-24.65 14.28c-1.53.76-3.34.7-4.82-.15L27.02 38.42l-.3-.2a3.49 3.49 0 0 1 .26-5.9Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
      />
      <path
        d="M98.86 62.11a1 1 0 0 0-.42.78c0 .3.19.44.42.3l3.17-1.83a1 1 0 0 0 .42-.78c0-.31-.19-.44-.42-.31z"
        fill="#1D1D1B"
      />
      <path
        d="M112 50.57 56.54 18.63a5.16 5.16 0 0 0-4.9-.15l-24.66 14.3c-1.27.94-1.52 2.8-.1 3.61l55.23 31.85c1.48.86 3.3.92 4.82.16l24.65-14.28c1.77-1.05 1.66-2.7.4-3.55Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeWidth={0.33}
      />
      <path
        d="M45.12 24.62a.7.7 0 0 1-.33.09.83.83 0 0 1-.4-.1c-.1-.07-.18-.17-.18-.27 0-.09.06-.18.18-.24.18-.1.48-.09.69.03.12.07.19.16.19.27 0 .09-.05.16-.15.22Zm-6.72 3.74c.12 0 .23-.02.31-.07l5.02-2.9c.1-.05.14-.13.14-.22 0-.1-.07-.2-.18-.26a.78.78 0 0 0-.7-.03l-5.02 2.9c-.1.05-.14.12-.14.21 0 .1.07.2.19.27.11.06.26.1.39.1Z"
        stroke="#1D1D1B"
        strokeWidth={0.33}
      />
      <path
        d="M95.28 53.63c1.83 1.13 1.84 2.97.01 4.1a6.88 6.88 0 0 1-6.62 0c-1.83-1.13-1.84-2.96-.02-4.1a6.87 6.87 0 0 1 6.63 0Z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      />
      <path
        d="M93.97 54.46c1.14.7 1.15 1.83.01 2.53-1.12.7-2.96.7-4.1 0-1.13-.7-1.14-1.83 0-2.53 1.12-.7 2.96-.7 4.1 0Z"
        fill="#CEF6E9"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      />
      <path
        d="M78.44 33.46c.6.35.6.92 0 1.27L55.67 47.96a2.4 2.4 0 0 1-2.18 0l-13.3-7.69c-.6-.35-.61-.91-.01-1.26l22.77-13.23a2.4 2.4 0 0 1 2.17 0z"
        fill="#fff"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      />
      <path
        d="M78.45 34.73 55.68 47.96a2.4 2.4 0 0 1-2.18 0l-13.32-7.69c-.3-.18-.45-.4-.45-.63v.5c0 .23.15.46.45.63l13.32 7.69c.6.35 1.58.35 2.18 0l22.77-13.23c.3-.18.45-.4.45-.63v-.5c0 .23-.16.45-.45.63Z"
        fill="#1D1D1B"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      />
      <path
        d="M62.65 30.8c.18.1.18.27 0 .37l-5.42 3.15c-.18.1-.47.1-.65 0s-.18-.27 0-.38L62 30.8c.18-.1.47-.1.65 0m10.02 1.5c.18.11.18.28 0 .39l-9.1 5.29c-.19.1-.48.1-.66 0s-.18-.27 0-.38l9.1-5.3c.18-.1.48-.1.66 0m-1.97-1.13c.19.1.19.28 0 .38l-9.1 5.3c-.18.1-.47.1-.66 0-.18-.11-.17-.28 0-.38l9.11-5.3c.18-.1.47-.1.65 0m-1.96-1.13c.18.1.18.27 0 .38l-9.1 5.3c-.19.1-.48.1-.66 0-.18-.11-.18-.28 0-.39l9.1-5.3c.19-.1.48-.1.66 0Z"
        fill="#1D1D1B"
      />
      <path
        d="M61.93 39.18c.6.34.61.91.01 1.26L54.8 44.6a2.4 2.4 0 0 1-2.18 0l-7.2-4.16c-.6-.35-.6-.91-.01-1.26l7.16-4.16a2.4 2.4 0 0 1 2.17 0l7.2 4.16Z"
        fill="#DFF9F0"
        stroke="#1D1D1B"
        strokeWidth={0.5}
      />
      <mask
        height={11}
        id="identity_svg__b"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'alpha',
        }}
        width={19}
        x={44}
        y={34}
      >
        <path
          d="M61.93 39.18c.6.34.61.91.01 1.26L54.8 44.6a2.4 2.4 0 0 1-2.18 0l-7.2-4.16c-.6-.35-.6-.91-.01-1.26l7.16-4.16a2.4 2.4 0 0 1 2.17 0l7.2 4.16Z"
          fill="#fff"
        />
      </mask>
      <g
        fill="#CEF6E9"
        mask="url(#identity_svg__b)"
        stroke="#1D1D1B"
        strokeMiterlimit={10}
        strokeWidth={0.5}
      >
        <path d="m62.03 40.32-7.26 4.22c-1.99-1.2-2-3.08-.03-4.22a8.14 8.14 0 0 1 7.29 0Zm-7.39-2.47c1.1.64 1.08 1.7-.07 2.36a4.49 4.49 0 0 1-4.08.05c-1.1-.63-1.07-1.69.08-2.36a4.5 4.5 0 0 1 4.07-.05Z" />
      </g>
      <path
        d="M9.38 86.12 2.6 82.2l6.72-3.9m28.27 16.32-6.72 3.9-6.77-3.9m42.1-24.79 6.77 3.9-6.72 3.91M37.99 61.33l6.72-3.91 6.76 3.9"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </svg>
  );
}
