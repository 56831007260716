import type { ReactElement, SVGProps } from 'react';

export function IconInfoRoundedOutlined(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="currentColor" height={16} width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#a)">
        <path d="M8 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7Zm0-1C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Z" />
        <path
          clipRule="evenodd"
          d="M8.65 4.82c0 .36-.29.65-.65.65-.36 0-.65-.29-.65-.65 0-.36.29-.65.65-.65.36 0 .65.29.65.65Z"
          fillRule="evenodd"
        />
        <path d="M8 12c-.28 0-.5-.22-.5-.5V6.95c0-.28.22-.5.5-.5s.5.22.5.5v4.55c0 .28-.22.5-.5.5Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
