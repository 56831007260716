import type { SVGProps, ReactElement } from 'react';

export function IconCheckmarkRoundedFilled(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Zm3.87 6-4.15 4.15-.72.71-.73-.71L4.13 8c-.2-.19-.2-.52 0-.71.2-.2.52-.2.72 0L7 9.42l4.15-4.13c.2-.2.53-.2.73 0 .2.2.19.52-.01.71Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
