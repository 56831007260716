import type { SVGProps, ReactElement } from 'react';

export function EyeIcon(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 6.00444C9.10034 6.00444 10.0006 6.90472 10.0006 8.00506C10.0006 9.10541 9.10034 10.0057 8 10.0057C6.89966 10.0057 5.99937 9.10541 5.99937 8.00506C5.99937 6.90472 6.89966 6.00444 8 6.00444ZM8 5.00412C6.33948 5.00412 4.99906 6.34454 4.99906 8.00506C4.99906 9.66558 6.33948 11.006 8 11.006C9.66052 11.006 11.0009 9.66558 11.0009 8.00506C11.0009 6.34454 9.66052 5.00412 8 5.00412Z" />
      <path
        clipRule="evenodd"
        d="M1.00781 8.00506C3.21851 1.25295 12.7715 1.25295 14.9822 8.00506C12.7715 14.7572 3.21851 14.7572 1.00781 8.00506ZM15.9625 8.25514C13.4817 16.0076 2.51829 16.0076 0.0375117 8.25514C-0.0125039 8.08509 -0.0125039 7.91503 0.0375117 7.74498C2.51829 -0.00744128 13.4817 -0.00744128 15.9625 7.74498C16.0125 7.91503 16.0125 8.08509 15.9625 8.25514Z"
        fillRule="evenodd"
      />
    </svg>
  );
}
